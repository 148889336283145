
.search-result .title h3 {
    font-size: 15px;
    margin: 0 0 15px;
    color: #333;
    float:left;
    text-align: justify;
}
.search-result .content {
    font-size: 12px;
    color: #333;
    float:left;
    text-align: justify;
}
.search-result .content a {
    font-size: 12px;
    float:left;
    text-align:justify;
    padding-right: 10px;
   
}
.well-header{
    border: 0;
    padding: 20px;
    min-height: 63px;
    background: #fff;
    box-shadow: none;
    border-radius: 3px;
    position: relative;
    max-height: 100000px;
    /* border-bottom: 2px solid #ccc; */
    transition: max-height 0.5s ease;
    -o-transition: max-height 0.5s ease;
    -ms-transition: max-height 0.5s ease;
    -moz-transition: max-height 0.5s ease;
    -webkit-transition: max-height 0.5s ease;
}
.well {
    border: 0;
    padding: 20px;
    min-height: 63px;
    background: #fff;
    box-shadow: none;
    border-radius: 3px;
    position: relative;
    
    /* border-bottom: 2px solid #ccc; */
    transition: max-height 0.5s ease;
    -o-transition: max-height 0.5s ease;
    -ms-transition: max-height 0.5s ease;
    -moz-transition: max-height 0.5s ease;
    -webkit-transition: max-height 0.5s ease;
}
.form-control {
    height: 45px;
    padding: 10px;
    font-size: 16px;
    box-shadow: none;
    border-radius: 0;
    position: relative;
}
.well .btn{
    transition: all .5s ease;
    margin-top: 0;
    width: 200px;
    height: 45px;
    border-radius: 0;
    color: #008080;
    font-weight: 600;
    background-color: #fff;
    border: 1px solid #194350;
    margin-bottom: 1em;
    
}