.accordion {
    max-width: auto;
  }
  .accordion-item {
    list-style: none;
  }
  .accordion-toggle {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    padding: 1em;
  }
  .accordion-toggle:hover {
    background-color: #e7f1ff;
  }
  .accordion-toggle h3 {
    margin: 0;
  }
  .accordion-content {
    background-color: #eee;
    padding: 0.5em;
  }