@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    height: 100px;
    transition: all 0.5s;
    z-index: 9999 ;
    transition: all 0.5s;
    background: #f4f8fa;
    /*important */
    /* overflow: hidden; 
    position: relative; */
  }
  
  #header.header-transparent {
    /* background:  #fff; */
    background: #f4f8fa;
  }
  
  #header.header-scrolled {
    background: rgba(30, 67, 86, 0.8);
    height: 60px;
  }
  
  #header .logo h1 {
    font-size: 28px;
    margin: 0;
    padding: 4px 0;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
  
  #header .logo h1 a, #header .logo h1 a:hover {
    color: #1e4356;
    text-decoration: none;
  }
  
  #header .logo img {
    /* padding: 0; */
    padding: 5px !important;
    margin: 0;
    max-height: 80px;
  }


    @media all and (max-width: 640px) {
      #header .container-fluid {
         background: #fff;
         padding: 10px 0 10px 0;
      }
    }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }
  
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .navbar li {
    position: relative;
  }
  
  .navbar a, .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-family: "IBM Plex Sans",sans-serif ;
    font-weight: 525;
    font-size: 16px;
    color: #000;
    text-decoration: none;
    white-space: nowrap;
    transition: 0.3s;
  }
  
  .navbar a i, .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  
  /* .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
    color: #94c045;
  } */

   .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a ::after {
   
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 4px;
    -webkit-transform-origin: scaleX(0);
            transform-origin: scaleX(0);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
    transition: all 0.5s ease;
    color: #94c045 ;
   }

   .navbar  .nav-item:hover > a ::after {
    width: 100%;
  }
  
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
  }
  
  .navbar .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 14px;
    text-transform: none;
    color: #1c3745;
  }
  
  .navbar .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
    color: #68A4C4;
  }
  
  .navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  
  .navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  .searchbar{
    position:relative;
    right:5px;
    padding:10px;
  }
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
    .navbar .dropdown .dropdown:hover > ul {
      left: -100%;
    }
  }
  
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: rgba(30, 67, 86);
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
    padding: 8px !important;

  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
    .navbar ul {
      display: none;
    }
  }
  
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 100px;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(17, 38, 48, 0.9);
    transition: 0.3s;
    z-index: 9999;
  }
  
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 55px;
    right: 15px;
  }
  
  .navbar-mobile ul {
    display: block;
    position: relative;
    top: 100px;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
    -webkit-transform: translate(0px, -170px);
            transform: translate(0px, -170px);
  }
 
  .navbar-mobile a {
    padding: 10px 20px;
    font-size: 15px;
    color: #1e4356;
  }
  .navbar-mobile li{
    display: flex;
    justify-content: center;
  }
  .navbar-mobile li:hover{
    background-color: #031e27;
  }
  .navbar-mobile a:hover, .navbar-mobile .active > a {
    color: #94c045;
  }
  
  .navbar-mobile .getstarted {
    margin: 15px;
  }
  
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  
  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
    color: #68A4C4;
  }
  
  .navbar-mobile .dropdown > .dropdown-active {
    display: block;
  }
  @media (min-width: 768px){
    .navbar-mobile ul {
      top:80px;
      -webkit-transform: translate(0px, -170px);
              transform: translate(0px, -170px);
      }
    }
   
    /*@media (min-width: 576px){
    #main{
        margin-top: 160px;
      }
    }*/
  /*--------------------------------------------------------------
# SearchBar
--------------------------------------------------------------*/
@media (max-width: 991px) {
    .search-box{
    -webkit-transform:translateX(-30px);
            transform:translateX(-30px)
    }
  }
  .mobile-search-toggle {
    color: rgba(30, 67, 86);
    font-size: 15px;
    padding: 10px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
  }
  .search-box{
    background: #1e4356;
    position: absolute;
    top:54px;
    right: 0.1%;
    width: 350px;
    height: 60px;
    box-shadow: 0 0 10px rgba(0,0,0, 0.5);
    border-top: 2px solid#f2ba49;
    display: none;
    z-index: 9999;
  }
  .search-box::before{
    content: "";
    position: absolute;
    top:-32px;
    right: 5px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid transparent;
    border-bottom: 14px solid #f2ba49;
  }
  .search-box input[type="text"]{
    width: 230px;
    height: 40px;
    padding: 5px 10px;
    margin-left: 23px;
    border-top: 1px solid #f2ba49;
    outline: none;
  }
  .search-box input[type="button"]{
    width: 70px;
    height: 40px;
    padding: 5px 0;
    background: #f2ba49;
    border: 1px solid #f2ba49;
    outline: none;
    cursor: pointer;
    color: #fff;
    margin-left: -5px;
    margin-top: 10px;
  }

#hero-no-slider {
  width: 100%;
  /* height: 40vh; */

  /* max-height: 400px; */
  overflow: hidden;
  position: relative;
  text-align: center;
 background:  url(/static/media/hero-bgimg.e252d7fe.jpg) center top no-repeat;
 /*background: #0a1f25;*/
}

#hero-no-slider::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(153, 123, 123, 0.5)), url(/static/media/hero-bgimg.e252d7fe.jpg) center top no-repeat;
  /*background: #0a1f25; */
 
}

#hero-no-slider h2 {
  color: #fff;
  margin-top: 120px;
  margin-bottom: 12px;
  font-size: 48px;
  font-weight: 700;
}

#hero-no-slider p {
  color: #fff;
}

#hero-no-slider .btn-get-started {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  background: #94c045;
  /* border: 2px solid #94c045; */
}

/* #hero-no-slider .btn-get-started:hover {
  background: #94c045;
  color: #fff;
  text-decoration: none;
} */

#hero-no-slider .btn-get-started:hover {
  /* background: #94c045; */
  color: #01081a;
  text-decoration: none;
}

@media (max-width: 575px) {
  #hero-no-slider h2 {
    font-size: 30px;
  }
}

/*--------------------------------------------------------------
# Our Services
--------------------------------------------------------------*/
.services {
    padding-bottom: 20px;
  }
  
  .services .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    margin: 0  0 40px 0;
    background: #fff;
    color: #111;
    box-shadow: 0 5px 26px 0 rgba(68, 88, 144, 0.14);
    transition: all 0.3s ease-in-out;
    text-align: center;
    border: 1px solid #fff;
  }
  
  .services .icon {
    margin: 0 auto 20px auto;
    padding-top: 17px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    width: 72px;
    height: 72px;
  }
  
  .services .icon i {
    font-size: 36px;
    line-height: 1;
  }
  
  .services .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .services .title a {
    color: #111;
  }
  
  .services .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
  }
  
  .services .icon-box-pink .icon {
    background: #fceef3;
  }
  
  .services .icon-box-pink .icon i {
    color: #ff689b;
  }
  
  .services .icon-box-pink:hover {
    border-color: #ff689b;
  }
  
  .services .icon-box-cyan .icon {
    background: #e6fdfc;
  }
  
  .services .icon-box-cyan .icon i {
    color: #3fcdc7;
  }
  
  .services .icon-box-cyan:hover {
    border-color: #3fcdc7;
  }
  
  .services .icon-box-green .icon {
    background: #eafde7;
  }
  
  .services .icon-box-green .icon i {
    color: #41cf2e;
  }
  
  .services .icon-box-green:hover {
    border-color: #41cf2e;
  }
  
  .services .icon-box-blue .icon {
    background: #e1eeff;
  }
  
  .services .icon-box-blue .icon i {
    color: #2282ff;
  }
  
  .services .icon-box-blue:hover {
    border-color: #2282ff;
  }

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us .container {
    box-shadow: 0 5px 25px 0 rgba(214, 215, 216, 0.6);
    background: #fff;
  }
  
  .why-us .icon-box + .icon-box {
    margin-top: 50px;
  }
  
  .why-us .icon-box .icon {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    background: #f3f8fa;
    border-radius: 6px;
    transition: 0.5s;
  }
  
  .why-us .icon-box .icon i {
    color: #68A4C4;
    font-size: 32px;
  }
  
  .why-us .icon-box:hover .icon {
    background: #68A4C4;
  }
  
  .why-us .icon-box:hover .icon i {
    color: #fff;
  }
  .why-us .card .special-issue-image{
    height: 350px;
  }
  .why-us .card .proposal-issue-image{
    height: 250px;
  }
  .why-us .card .Event-proposal-image{
    height: 150px;
  }
  .why-us .icon-box .title {
    margin-left: 95px;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 18px;
    text-transform: uppercase;
  }
  
  .why-us .icon-box .title a {
    color: #343a40;
    transition: 0.3s;
  }
  
  .why-us .icon-box .title a:hover {
    color: #68A4C4;
  }
  
  .why-us .icon-box .description {
    margin-left: 95px;
    line-height: 24px;
    font-size: 14px;
  }
  
  .why-us .video-box {
    position: relative;
  }
  
  .why-us .video-box img {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
  .why-us .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#68A4C4 50%, rgba(104, 164, 196, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  
  .why-us .play-btn::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-40%) translateY(-50%);
            transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .why-us .play-btn::before {
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate-btn 2s;
    animation: pulsate-btn 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(104, 164, 196, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  
  .why-us .play-btn:hover::after {
    border-left: 15px solid #68A4C4;
    -webkit-transform: scale(20);
            transform: scale(20);
  }
  
  .why-us .play-btn:hover::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-40%) translateY(-50%);
            transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    -webkit-animation: none;
    animation: none;
    border-radius: 0;
  }
  @-webkit-keyframes pulsate-btn {
    0% {
      -webkit-transform: scale(0.6, 0.6);
              transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  @keyframes pulsate-btn {
    0% {
      -webkit-transform: scale(0.6, 0.6);
              transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
      opacity: 0;
    }
  }
/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .row + .row {
    margin-top: 100px;
  }
  
  .features h3 {
    font-weight: 400;
    font-size: 24px;
  }
  
  .features ul {
    list-style: none;
    padding: 0;
  }
  
  .features ul li {
    padding-bottom: 10px;
  }
  
  .features ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #68A4C4;
  }
  
  .features p:last-child {
    margin-bottom: 0;
  }


/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    background: #194350;
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;
  }
  
  #footer .footer-newsletter {
    padding: 50px 0;
    background: #0d2735;
  }
  
  #footer .footer-newsletter h4 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
    color: #a2cce3;
  }
  
  #footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
  }
  
  #footer .footer-newsletter form input[type="email"] {
    border: 0;
    padding: 4px;
    width: calc(100% - 100px);
  }
  
  #footer .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    margin: 3px;
    background: #68A4C4;
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
  }
  
  #footer .footer-newsletter form input[type="submit"]:hover {
    background: #468db3;
  }
  
  #footer .footer-top {
    background:#194350;
    border-top: 1px solid #17455e;
    border-bottom: 1px solid #ffffff;
    padding: 60px 0 30px 0;
  }
  
  #footer .footer-top .footer-info {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-info h3 {
    font-size: 18px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    color: #ffffff;
    font-weight: 600;
  }
  
  #footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Roboto", sans-serif;
    color: #fff;
  }
  
  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background:#94c045;
    /* border: 1px solid #ffffff; */
    border: 1px solid #94c045; 
    color: #ffffff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .footer-top .social-links a:hover {
    background: #94c045;
    /* border: 1px solid #ffffff;  */
    
    color: #194350;
    text-decoration: none;
  }
  
  #footer .footer-top h4 {
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #ffffff;
    font-size: 18px;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    color: #a2cce3;
  }
  
  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-contact p {
    line-height: 26px;
  }
  
  #footer .copyright {
    text-align: center;
    padding-top: 30px;
  }
  
  #footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff;
  }
  
  #footer .credits a {
    color: #a2cce3;
  }
/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 25px;
    bottom: 15px;
    z-index: 99999;
    background: #94c045;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #392933;
    transition: all 0.4s;
  }
  
  .back-to-top i {
    font-size: 24px;
    color: #392933;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: #94c045;
    border-color: #ffffff;
    color: #fff;
  }
  .back-to-top i:hover {
    color: #fff;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }  

  .scroll-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 25px;
    bottom: 15px;
    z-index: 99999;
    background: #94c045;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #392933;
    transition: all 0.4s;
  }

  @media screen and (min-width:1400px) {
    .styles_scroll-to-top__2A70v{
      margin: 0 auto;
      width: 1400px;
    }
  }
#laccordion-toggle {
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    padding: 1em;
    justify-content: left;
}
.accordion-item {
    list-style: none;
    text-align: left;
}



/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
  padding: 40px 0 20px 0;
}
.blog hr {
  height: 1px;
  margin-left: 0px;
  margin-bottom:15px;
}
.blog .hr-warning{
  background-image: -webkit-linear-gradient(left, rgba(210,105,30,.8), rgba(210,105,30,.6), rgba(0,0,0,0));
}
.blog .hr-success{
  background-image: -webkit-linear-gradient(left, rgba(15,157,88,.8), rgba(15, 157, 88,.6), rgba(0,0,0,0));
}
.blog .hr-primary{
  background-image: -webkit-linear-gradient(left, rgba(66,133,244,.8), rgba(66, 133, 244,.6), rgba(0,0,0,0));
}
.blog .hr-danger{
  background-image: -webkit-linear-gradient(left, rgba(244,67,54,.8), rgba(244,67,54,.6), rgba(0,0,0,0));
}
.blog .entry {
  padding: 30px;
  margin-bottom: 60px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .entry .entry-img {
  max-height: 440px;
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog .entry .entry-title {
  font-size: 28px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
  color: #1e4356;
  transition: 0.3s;
}

.blog .entry .entry-title a:hover {
  color: #68A4C4;
}

.blog .entry .entry-meta {
  margin-bottom: 15px;
  color: #72afce;
}

.blog .entry .entry-meta ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .entry .entry-meta ul li + li {
  padding-left: 20px;
}

.blog .entry .entry-meta i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
}

.blog .entry .entry-meta a {
  color: #777777;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog .entry .entry-content p {
  font-size: 12px;
  line-height: 24px;
  text-align: justify;
}

.blog .entry .entry-content .read-more {
  text-align-last: right;
}

.blog .entry .entry-content .read-more a {
  display: inline-block;
  background: #68A4C4;
  color: #fff;
  padding: 6px 20px;
  transition: 0.3s;
  font-size: 14px;
}

.blog .entry .entry-content .read-more a:hover {
  background: #7aafcb;
}


.blog .apply-filter {
  text-align-last: center;
}

.blog .apply-filter a {
  display: inline-block;
  background: #68A4C4;
  color: #fff;
  padding: 6px 20px;
  transition: 0.3s;
  font-size: 14px;
  text-decoration: none;
}

.blog .apply-filter a:hover {
  background: #7aafcb;
}





.blog .entry .entry-content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog .entry .entry-content blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .entry .entry-content blockquote p {
  color: #444;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog .entry .entry-content blockquote::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #1e4356;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .entry .entry-footer {
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;
}

.blog .entry .entry-footer i {
  color: #4c99c1;
  display: inline;
}

.blog .entry .entry-footer a {
  color: #255269;
  transition: 0.3s;
}

.blog .entry .entry-footer a:hover {
  color: #68A4C4;
}

.blog .entry .entry-footer .cats {
  list-style: none;
  display: inline;
  padding: 0 10px 0 0;
  font-size: 14px;
}

.blog .entry .entry-footer .cats li {
  display: inline-block;
}

.blog .entry .entry-footer .tags {
  list-style: none;
  display: inline;
  padding: 0 10px 0 0;
  font-size: 14px;
}

.blog .entry .entry-footer .tags li {
  display: inline-block;
}

.blog .entry .entry-footer .tags li + li::before {
  padding-right: 6px;
  color: #6c757d;
  content: ",";
}

.blog .entry .entry-footer .share {
  font-size: 16px;
}

.blog .entry .entry-footer .share i {
  padding-left: 5px;
}

.blog .entry-single {
  margin-bottom: 30px;
}

.blog .blog-author {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
  width: 120px;
  margin-right: 20px;
}

.blog .blog-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
  color: #1e4356;
}

.blog .blog-author .social-links {
  margin: 0 10px 10px 0;
}

.blog .blog-author .social-links a {
  color: rgba(30, 67, 86, 0.5);
  margin-right: 5px;
}

.blog .blog-author p {
  font-style: italic;
  color: #b7b7b7;
}

.blog .blog-comments {
  margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
  font-weight: bold;
}

.blog .blog-comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .blog-comments .comment .comment-img {
  margin-right: 14px;
}

.blog .blog-comments .comment .comment-img img {
  width: 60px;
}

.blog .blog-comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog .blog-comments .comment h5 a {
  font-weight: bold;
  color: #444;
  transition: 0.3s;
}

.blog .blog-comments .comment h5 a:hover {
  color: #68A4C4;
}

.blog .blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: #1e4356;
}

.blog .blog-comments .comment h5 .reply i {
  font-size: 20px;
}

.blog .blog-comments .comment time {
  display: block;
  font-size: 14px;
  color: #2b607c;
  margin-bottom: 5px;
}

.blog .blog-comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .blog-comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog .blog-comments .reply-form p {
  font-size: 14px;
}

.blog .blog-comments .reply-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form input:focus {
  box-shadow: none;
  border-color: #b1d0e1;
}

.blog .blog-comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: #b1d0e1;
}

.blog .blog-comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .blog-comments .reply-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: #1e4356;
}

.blog .blog-comments .reply-form .btn-primary:hover {
  background-color: #255269;
}

.blog .blog-pagination {
  color: #387ea2;
}

.blog .blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog .blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}

.blog .blog-pagination li a {
  color: #1e4356;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .blog-pagination li.active, .blog .blog-pagination li:hover {
  background: #68A4C4;
}

.blog .blog-pagination li.active a, .blog .blog-pagination li:hover a {
  color: #fff;
}
/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog .row:after{
   content: "."; 
   visibility: hidden; 
   display: block; 
   height: 0; 
   clear: both;

}


.blog .card{
  padding: 10px 10px 10px 10px;
  box-sizing: border-box;
  margin: 0 0 60px 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  max-width: 300px;
}
.blog .card img{
 /* overflow: hidden;
  height: 50px;
  padding: 2px;*/
  width: 100%;
    height: 5vw;
    object-fit: cover;
}
.blog .sidebar {
  
  padding: 30px 30px 30px 30px;
  box-sizing: border-box;
  margin: 0 0 60px 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  top: 80px;

}

.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 0 0;
  margin: 0 0 15px 0;
  color: #1e4356;
  position: relative;
}
.blog .abtlistgrp .card{
  padding: 0 0 0 0;
}
.blog .abtlistgrp .card {
 padding: 0px 0px 0px 0px;
 box-sizing: border-box;
 margin: 20px 0 0 0;
 border-radius: 0;
 box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
 max-width: 500px;
}
.blog .abtlistgrp .card .btn-submit {
 background-color: #011520;
 color: #ffffff;
 border-radius: 0;
 max-width: 500px;
}
.abtlistgrp .card .list-group i{
 margin-right: 20px;
}
.blog .abtlistgrp .card .list-group .btn {
 padding: .5rem 1rem;
 font-size: 1.25rem;
 border-radius: .3rem;
 width: 100%;
}
#sidebar-accordion {
    margin-bottom: -1px;
    background-color: #194350;
    color: #fff;
    border: 1px solid #ccc;
}
#sidebar-accordion .accordion-text{
    border: 1px solid #194350;
}

  /*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
    padding: 40px 0 20px 0;
  }
  .blog hr {
    height: 1px;
    margin-left: 0px;
    margin-bottom:15px;
  }
  .blog .hr-warning{
    background-image: -webkit-linear-gradient(left, rgba(210,105,30,.8), rgba(210,105,30,.6), rgba(0,0,0,0));
  }
  .blog .hr-success{
    background-image: -webkit-linear-gradient(left, rgba(15,157,88,.8), rgba(15, 157, 88,.6), rgba(0,0,0,0));
  }
  .blog .hr-primary{
    background-image: -webkit-linear-gradient(left, rgba(66,133,244,.8), rgba(66, 133, 244,.6), rgba(0,0,0,0));
  }
  .blog .hr-danger{
    background-image: -webkit-linear-gradient(left, rgba(244,67,54,.8), rgba(244,67,54,.6), rgba(0,0,0,0));
  }
  .blog .entry {
    padding: 30px;
    margin-bottom: 60px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .entry .entry-img {
    max-height: 440px;
    margin: -30px -30px 20px -30px;
    overflow: hidden;
  }
  
  .blog .entry .entry-title {
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0 0 20px 0;
  }
  
  .blog .entry .entry-title a {
    color: #1e4356;
    transition: 0.3s;
  }
  
  .blog .entry .entry-title a:hover {
    color: #68A4C4;
  }
  
  .blog .entry .entry-meta {
    margin-bottom: 15px;
    color: #72afce;
  }
  
  .blog .entry .entry-meta ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  
  .blog .entry .entry-meta ul li + li {
    padding-left: 20px;
  }
  
  .blog .entry .entry-meta i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0;
  }
  
  .blog .entry .entry-meta a {
    color: #777777;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
  }
  
  .blog .entry .entry-content p {
    font-size: 12px;
    line-height: 24px;
    text-align: justify;
  }
  
  .blog .entry .entry-content .read-more {
    text-align-last: right;
  }
  
  .blog .entry .entry-content .read-more a {
    display: inline-block;
    background: #68A4C4;
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
  }
  
  .blog .entry .entry-content .read-more a:hover {
    background: #7aafcb;
  }
  
  
  .blog .apply-filter {
    text-align-last: center;
  }
  
  .blog .apply-filter a {
    display: inline-block;
    background: #68A4C4;
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
    text-decoration: none;
  }
  
  .blog .apply-filter a:hover {
    background: #7aafcb;
  }
  
  
  
  
  
  .blog .entry .entry-content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
  }
  
  .blog .entry .entry-content blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
  }
  
  .blog .entry .entry-content blockquote p {
    color: #444;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
  }
  
  .blog .entry .entry-content blockquote::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #1e4356;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .blog .entry .entry-footer {
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
  }
  
  .blog .entry .entry-footer i {
    color: #4c99c1;
    display: inline;
  }
  
  .blog .entry .entry-footer a {
    color: #255269;
    transition: 0.3s;
  }
  
  .blog .entry .entry-footer a:hover {
    color: #68A4C4;
  }
  
  .blog .entry .entry-footer .cats {
    list-style: none;
    display: inline;
    padding: 0 10px 0 0;
    font-size: 14px;
  }
  
  .blog .entry .entry-footer .cats li {
    display: inline-block;
  }
  
  .blog .entry .entry-footer .tags {
    list-style: none;
    display: inline;
    padding: 0 10px 0 0;
    font-size: 14px;
  }
  
  .blog .entry .entry-footer .tags li {
    display: inline-block;
  }
  
  .blog .entry .entry-footer .tags li + li::before {
    padding-right: 6px;
    color: #6c757d;
    content: ",";
  }
  
  .blog .entry .entry-footer .share {
    font-size: 16px;
  }
  
  .blog .entry .entry-footer .share i {
    padding-left: 5px;
  }
  
  .blog .entry-single {
    margin-bottom: 30px;
  }
  
  .blog .blog-author {
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .blog-author img {
    width: 120px;
    margin-right: 20px;
  }
  
  .blog .blog-author h4 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0px;
    padding: 0;
    color: #1e4356;
  }
  
  .blog .blog-author .social-links {
    margin: 0 10px 10px 0;
  }
  
  .blog .blog-author .social-links a {
    color: rgba(30, 67, 86, 0.5);
    margin-right: 5px;
  }
  
  .blog .blog-author p {
    font-style: italic;
    color: #b7b7b7;
  }
  
  .blog .blog-comments {
    margin-bottom: 30px;
  }
  
  .blog .blog-comments .comments-count {
    font-weight: bold;
  }
  
  .blog .blog-comments .comment {
    margin-top: 30px;
    position: relative;
  }
  
  .blog .blog-comments .comment .comment-img {
    margin-right: 14px;
  }
  
  .blog .blog-comments .comment .comment-img img {
    width: 60px;
  }
  
  .blog .blog-comments .comment h5 {
    font-size: 16px;
    margin-bottom: 2px;
  }
  
  .blog .blog-comments .comment h5 a {
    font-weight: bold;
    color: #444;
    transition: 0.3s;
  }
  
  .blog .blog-comments .comment h5 a:hover {
    color: #68A4C4;
  }
  
  .blog .blog-comments .comment h5 .reply {
    padding-left: 10px;
    color: #1e4356;
  }
  
  .blog .blog-comments .comment h5 .reply i {
    font-size: 20px;
  }
  
  .blog .blog-comments .comment time {
    display: block;
    font-size: 14px;
    color: #2b607c;
    margin-bottom: 5px;
  }
  
  .blog .blog-comments .comment.comment-reply {
    padding-left: 40px;
  }
  
  .blog .blog-comments .reply-form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .blog-comments .reply-form h4 {
    font-weight: bold;
    font-size: 22px;
  }
  
  .blog .blog-comments .reply-form p {
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form input {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form input:focus {
    box-shadow: none;
    border-color: #b1d0e1;
  }
  
  .blog .blog-comments .reply-form textarea {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form textarea:focus {
    box-shadow: none;
    border-color: #b1d0e1;
  }
  
  .blog .blog-comments .reply-form .form-group {
    margin-bottom: 25px;
  }
  
  .blog .blog-comments .reply-form .btn-primary {
    border-radius: 4px;
    padding: 10px 20px;
    border: 0;
    background-color: #1e4356;
  }
  
  .blog .blog-comments .reply-form .btn-primary:hover {
    background-color: #255269;
  }
  
  .blog .blog-pagination {
    color: #387ea2;
  }
  
  .blog .blog-pagination ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .blog .blog-pagination li {
    margin: 0 5px;
    transition: 0.3s;
  }
  
  .blog .blog-pagination li a {
    color: #1e4356;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .blog .blog-pagination li.active, .blog .blog-pagination li:hover {
    background: #68A4C4;
  }
  
  .blog .blog-pagination li.active a, .blog .blog-pagination li:hover a {
    color: #fff;
  }
  /*--------------------------------------------------------------
  # Blog
  --------------------------------------------------------------*/
  .blog .row:after{
     content: "."; 
     visibility: hidden; 
     display: block; 
     height: 0; 
     clear: both;
  
  }
  
  
  .blog .card{
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    max-width: 300px;
  }
  .blog .card img{
   /* overflow: hidden;
    height: 50px;
    padding: 2px;*/
    width: 100%;
      height: 5vw;
      object-fit: cover;
  }
  .blog .sidebar {
    
    padding: 30px 30px 30px 30px;
    box-sizing: border-box;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    top: 80px;
  
  }
  
  .blog .sidebar .sidebar-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 15px 0;
    color: #1e4356;
    position: relative;
  }
  .blog .abtlistgrp .card{
    padding: 0 0 0 0;
  }
  .blog .abtlistgrp .card {
   padding: 0px 0px 0px 0px;
   box-sizing: border-box;
   margin: 20px 0 0 0;
   border-radius: 0;
   box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
   max-width: 500px;
  }
  .blog .abtlistgrp .card .btn-submit {
   background-color: #011520;
   color: #ffffff;
   border-radius: 0;
   max-width: 500px;
  }
  .abtlistgrp .card .list-group i{
   margin-right: 20px;
  }
  .blog .abtlistgrp .card .list-group .btn {
   padding: .5rem 1rem;
   font-size: 1.25rem;
   border-radius: .3rem;
   width: 100%;
 }

  /*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
    padding: 40px 0 20px 0;
  }
  .blog hr {
    height: 1px;
    margin-left: 0px;
    margin-bottom:15px;
  }
  .blog .hr-warning{
    background-image: -webkit-linear-gradient(left, rgba(210,105,30,.8), rgba(210,105,30,.6), rgba(0,0,0,0));
  }
  .blog .hr-success{
    background-image: -webkit-linear-gradient(left, rgba(15,157,88,.8), rgba(15, 157, 88,.6), rgba(0,0,0,0));
  }
  .blog .hr-primary{
    background-image: -webkit-linear-gradient(left, rgba(66,133,244,.8), rgba(66, 133, 244,.6), rgba(0,0,0,0));
  }
  .blog .hr-danger{
    background-image: -webkit-linear-gradient(left, rgba(244,67,54,.8), rgba(244,67,54,.6), rgba(0,0,0,0));
  }
  .blog .entry {
    padding: 30px;
    margin-bottom: 60px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .entry .entry-img {
    max-height: 440px;
    margin: -30px -30px 20px -30px;
    overflow: hidden;
  }
  
  .blog .entry .entry-title {
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0 0 20px 0;
  }
  
  .blog .entry .entry-title a {
    color: #1e4356;
    transition: 0.3s;
  }
  
  .blog .entry .entry-title a:hover {
    color: #68A4C4;
  }
  
  .blog .entry .entry-meta {
    margin-bottom: 15px;
    color: #72afce;
  }
  
  .blog .entry .entry-meta ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  
  .blog .entry .entry-meta ul li + li {
    padding-left: 20px;
  }
  
  .blog .entry .entry-meta i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0;
  }
  
  .blog .entry .entry-meta a {
    color: #777777;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
  }
  
  .blog .entry .entry-content p {
    font-size: 12px;
    line-height: 24px;
    text-align: justify;
  }
  
  .blog .entry .entry-content .read-more {
    text-align-last: right;
  }
  
  .blog .entry .entry-content .read-more a {
    display: inline-block;
    background: #68A4C4;
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
  }
  
  .blog .entry .entry-content .read-more a:hover {
    background: #7aafcb;
  }
  
  
  .blog .apply-filter {
    text-align-last: center;
  }
  
  .blog .apply-filter a {
    display: inline-block;
    background: #68A4C4;
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
    text-decoration: none;
  }
  
  .blog .apply-filter a:hover {
    background: #7aafcb;
  }
  
  
  
  
  
  .blog .entry .entry-content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
  }
  
  .blog .entry .entry-content blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
  }
  
  .blog .entry .entry-content blockquote p {
    color: #444;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
  }
  
  .blog .entry .entry-content blockquote::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #1e4356;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .blog .entry .entry-footer {
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
  }
  
  .blog .entry .entry-footer i {
    color: #4c99c1;
    display: inline;
  }
  
  .blog .entry .entry-footer a {
    color: #255269;
    transition: 0.3s;
  }
  
  .blog .entry .entry-footer a:hover {
    color: #68A4C4;
  }
  
  .blog .entry .entry-footer .cats {
    list-style: none;
    display: inline;
    padding: 0 10px 0 0;
    font-size: 14px;
  }
  
  .blog .entry .entry-footer .cats li {
    display: inline-block;
  }
  
  .blog .entry .entry-footer .tags {
    list-style: none;
    display: inline;
    padding: 0 10px 0 0;
    font-size: 14px;
  }
  
  .blog .entry .entry-footer .tags li {
    display: inline-block;
  }
  
  .blog .entry .entry-footer .tags li + li::before {
    padding-right: 6px;
    color: #6c757d;
    content: ",";
  }
  
  .blog .entry .entry-footer .share {
    font-size: 16px;
  }
  
  .blog .entry .entry-footer .share i {
    padding-left: 5px;
  }
  
  .blog .entry-single {
    margin-bottom: 30px;
  }
  
  .blog .blog-author {
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .blog-author img {
    width: 120px;
    margin-right: 20px;
  }
  
  .blog .blog-author h4 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0px;
    padding: 0;
    color: #1e4356;
  }
  
  .blog .blog-author .social-links {
    margin: 0 10px 10px 0;
  }
  
  .blog .blog-author .social-links a {
    color: rgba(30, 67, 86, 0.5);
    margin-right: 5px;
  }
  
  .blog .blog-author p {
    font-style: italic;
    color: #b7b7b7;
  }
  
  .blog .blog-comments {
    margin-bottom: 30px;
  }
  
  .blog .blog-comments .comments-count {
    font-weight: bold;
  }
  
  .blog .blog-comments .comment {
    margin-top: 30px;
    position: relative;
  }
  
  .blog .blog-comments .comment .comment-img {
    margin-right: 14px;
  }
  
  .blog .blog-comments .comment .comment-img img {
    width: 60px;
  }
  
  .blog .blog-comments .comment h5 {
    font-size: 16px;
    margin-bottom: 2px;
  }
  
  .blog .blog-comments .comment h5 a {
    font-weight: bold;
    color: #444;
    transition: 0.3s;
  }
  
  .blog .blog-comments .comment h5 a:hover {
    color: #68A4C4;
  }
  
  .blog .blog-comments .comment h5 .reply {
    padding-left: 10px;
    color: #1e4356;
  }
  
  .blog .blog-comments .comment h5 .reply i {
    font-size: 20px;
  }
  
  .blog .blog-comments .comment time {
    display: block;
    font-size: 14px;
    color: #2b607c;
    margin-bottom: 5px;
  }
  
  .blog .blog-comments .comment.comment-reply {
    padding-left: 40px;
  }
  
  .blog .blog-comments .reply-form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .blog-comments .reply-form h4 {
    font-weight: bold;
    font-size: 22px;
  }
  
  .blog .blog-comments .reply-form p {
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form input {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form input:focus {
    box-shadow: none;
    border-color: #b1d0e1;
  }
  
  .blog .blog-comments .reply-form textarea {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form textarea:focus {
    box-shadow: none;
    border-color: #b1d0e1;
  }
  
  .blog .blog-comments .reply-form .form-group {
    margin-bottom: 25px;
  }
  
  .blog .blog-comments .reply-form .btn-primary {
    border-radius: 4px;
    padding: 10px 20px;
    border: 0;
    background-color: #1e4356;
  }
  
  .blog .blog-comments .reply-form .btn-primary:hover {
    background-color: #255269;
  }
  
  .blog .blog-pagination {
    color: #387ea2;
  }
  
  .blog .blog-pagination ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .blog .blog-pagination li {
    margin: 0 5px;
    transition: 0.3s;
  }
  
  .blog .blog-pagination li a {
    color: #1e4356;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .blog .blog-pagination li.active, .blog .blog-pagination li:hover {
    background: #68A4C4;
  }
  
  .blog .blog-pagination li.active a, .blog .blog-pagination li:hover a {
    color: #fff;
  }
  /*--------------------------------------------------------------
  # Blog
  --------------------------------------------------------------*/
  .blog .row:after{
     content: "."; 
     visibility: hidden; 
     display: block; 
     height: 0; 
     clear: both;
  
  }
  
  
  .blog .card{
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    max-width: 300px;
  }
  .blog .card img{
   /* overflow: hidden;
    height: 50px;
    padding: 2px;*/
    width: 100%;
      height: 5vw;
      object-fit: cover;
  }
  .blog .sidebar {
    
    padding: 30px 30px 30px 30px;
    box-sizing: border-box;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    top: 80px;
  
  }
  
  .blog .sidebar .sidebar-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 15px 0;
    color: #1e4356;
    position: relative;
  }
  .blog .abtlistgrp .card{
    padding: 0 0 0 0;
  }
  .blog .abtlistgrp .card {
   padding: 0px 0px 0px 0px;
   box-sizing: border-box;
   margin: 20px 0 0 0;
   border-radius: 0;
   box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
   max-width: 500px;
  }
  .blog .abtlistgrp .card .btn-submit {
   background-color: #011520;
   color: #ffffff;
   border-radius: 0;
   max-width: 500px;
  }
  .abtlistgrp .card .list-group i{
   margin-right: 20px;
  }
  .blog .abtlistgrp .card .list-group .btn {
   padding: .5rem 1rem;
   font-size: 1.25rem;
   border-radius: .3rem;
   width: 100%;
 }
/*--------------------------------------------------------------
# Our Skills
--------------------------------------------------------------*/
.skills {
    padding: 60px 0;
  }
  
  .skills .progress {
    height: 35px;
    margin-bottom: 10px;
  }
  
  .skills .progress .skill {
    line-height: 35px;
    padding: 0;
    margin: 0 0 0 20px;
    text-transform: uppercase;
  }
  
  .skills .progress .skill .val {
    float: right;
    font-style: normal;
    margin: 0 20px 0 0;
  }
  
  .skills .progress-bar {
    width: 1px;
    text-align: left;
    transition: .9s;
  }

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us .container {
    box-shadow: 0 5px 25px 0 rgba(214, 215, 216, 0.6);
    background: #fff;
  }
  
  .why-us .icon-box + .icon-box {
    margin-top: 50px;
  }
  
  .why-us .icon-box .icon {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    background: #f3f8fa;
    border-radius: 6px;
    transition: 0.5s;
  }
  
  .why-us .icon-box .icon i {
    color: #68A4C4;
    font-size: 32px;
  }
  
  .why-us .icon-box:hover .icon {
    background: #68A4C4;
  }
  
  .why-us .icon-box:hover .icon i {
    color: #fff;
  }
  
  .why-us .icon-box .title {
    margin-left: 95px;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 18px;
    text-transform: uppercase;
  }
  
  .why-us .icon-box .title a {
    color: #343a40;
    transition: 0.3s;
  }
  
  .why-us .icon-box .title a:hover {
    color: #68A4C4;
  }
  
  .why-us .icon-box .description {
    margin-left: 95px;
    line-height: 24px;
    font-size: 14px;
  }
  
  .why-us .video-box {
    position: relative;
  }
  
  .why-us .video-box img {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
  .why-us .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#68A4C4 50%, rgba(104, 164, 196, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  
  .why-us .play-btn::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-40%) translateY(-50%);
            transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .why-us .play-btn::before {
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate-btn 2s;
    animation: pulsate-btn 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(104, 164, 196, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  
  .why-us .play-btn:hover::after {
    border-left: 15px solid #68A4C4;
    -webkit-transform: scale(20);
            transform: scale(20);
  }
  
  .why-us .play-btn:hover::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-40%) translateY(-50%);
            transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    -webkit-animation: none;
    animation: none;
    border-radius: 0;
  }
  
  
  
  
  @-webkit-keyframes pulsate-btn {
    0% {
      -webkit-transform: scale(0.6, 0.6);
              transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  @keyframes pulsate-btn {
    0% {
      -webkit-transform: scale(0.6, 0.6);
              transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
      opacity: 0;
    }
  }
      
/*--------------------------------------------------------------
# Hero No Slider Section
--------------------------------------------------------------*/
#hero-no-slider {
    width: 100%;
    height: 75vh;
    overflow: hidden;
    position: relative;
    text-align: center;
  }
  
  #hero-no-slider::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
   /* background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(153, 123, 123, 0.5)), url("../../public/assets/img/hero-bgimg.jpg") center top no-repeat;*/
   background-color: #0f1c20;
   
  }
  
  #hero-no-slider h2 {
    color: #fff;
    margin-bottom: 12px;
    font-size: 48px;
    font-weight: 700;
  }
  
  #hero-no-slider p {
    color: #fff;
  }
  
  #hero-no-slider .btn-get-started {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    border: 2px solid #94c045;
  }
  
  #hero-no-slider .btn-get-started:hover {
    background: #94c045;
    color: #fff;
    text-decoration: none;
  }
  
  @media (max-width: 575px) {
    #hero-no-slider h2 {
      font-size: 30px;
    }
  }

  /*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
    padding: 15px 0;
    background-color: #f3f8fa;
    min-height: 40px;
  }
  
  .breadcrumbs h2 {
    font-size: 28px;
    font-weight: 300;
  }
  
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .breadcrumbs ol li + li {
    padding-left: 10px;
  }
  
  .breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #6c757d;
    content: "/";
  }
  
  @media (max-width: 768px) {
    .breadcrumbs .d-flex {
      display: block !important;
    }
    .breadcrumbs ol {
      display: block;
    }
    .breadcrumbs ol li {
      display: inline-block;
    }
  }
  /*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
    padding: 40px 0 20px 0;
  }
  .blog hr {
    height: 1px;
    margin-left: 0px;
    margin-bottom:15px;
  }
  .blog .hr-warning{
    background-image: -webkit-linear-gradient(left, rgba(210,105,30,.8), rgba(210,105,30,.6), rgba(0,0,0,0));
  }
  .blog .hr-success{
    background-image: -webkit-linear-gradient(left, rgba(15,157,88,.8), rgba(15, 157, 88,.6), rgba(0,0,0,0));
  }
  .blog .hr-primary{
    background-image: -webkit-linear-gradient(left, rgba(66,133,244,.8), rgba(66, 133, 244,.6), rgba(0,0,0,0));
  }
  .blog .hr-danger{
    background-image: -webkit-linear-gradient(left, rgba(244,67,54,.8), rgba(244,67,54,.6), rgba(0,0,0,0));
  }
  .blog .entry {
    padding: 30px;
    margin-bottom: 60px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .entry .entry-img {
    max-height: 440px;
    margin: -30px -30px 20px -30px;
    overflow: hidden;
  }
  
  .blog .entry .entry-title {
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0 0 20px 0;
  }
  
  .blog .entry .entry-title a {
    color: #1e4356;
    transition: 0.3s;
  }
  
  .blog .entry .entry-title a:hover {
    color: #68A4C4;
  }
  
  .blog .entry .entry-meta {
    margin-bottom: 15px;
    color: #72afce;
  }
  
  .blog .entry .entry-meta ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  
  .blog .entry .entry-meta ul li + li {
    padding-left: 20px;
  }
  
  .blog .entry .entry-meta i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0;
  }
  
  .blog .entry .entry-meta a {
    color: #777777;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
  }
  
  .blog .entry .entry-content p {
    font-size: 12px;
    line-height: 24px;
    text-align: justify;
  }
  
  .blog .entry .entry-content .read-more {
    text-align-last: right;
  }
  
  .blog .entry .entry-content .read-more a {
    display: inline-block;
    background: #68A4C4;
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
  }
  
  .blog .entry .entry-content .read-more a:hover {
    background: #7aafcb;
  }
  
  
  .blog .apply-filter {
    text-align-last: center;
  }
  
  .blog .apply-filter a {
    display: inline-block;
    background: #68A4C4;
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
    text-decoration: none;
  }
  
  .blog .apply-filter a:hover {
    background: #7aafcb;
  }
  
  
  
  
  
  .blog .entry .entry-content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
  }
  
  .blog .entry .entry-content blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
  }
  
  .blog .entry .entry-content blockquote p {
    color: #444;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
  }
  
  .blog .entry .entry-content blockquote::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #1e4356;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .blog .entry .entry-footer {
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
  }
  
  .blog .entry .entry-footer i {
    color: #4c99c1;
    display: inline;
  }
  
  .blog .entry .entry-footer a {
    color: #255269;
    transition: 0.3s;
  }
  
  .blog .entry .entry-footer a:hover {
    color: #68A4C4;
  }
  
  .blog .entry .entry-footer .cats {
    list-style: none;
    display: inline;
    padding: 0 10px 0 0;
    font-size: 14px;
  }
  
  .blog .entry .entry-footer .cats li {
    display: inline-block;
  }
  
  .blog .entry .entry-footer .tags {
    list-style: none;
    display: inline;
    padding: 0 10px 0 0;
    font-size: 14px;
  }
  
  .blog .entry .entry-footer .tags li {
    display: inline-block;
  }
  
  .blog .entry .entry-footer .tags li + li::before {
    padding-right: 6px;
    color: #6c757d;
    content: ",";
  }
  
  .blog .entry .entry-footer .share {
    font-size: 16px;
  }
  
  .blog .entry .entry-footer .share i {
    padding-left: 5px;
  }
  
  .blog .entry-single {
    margin-bottom: 30px;
  }
  
  .blog .blog-author {
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .blog-author img {
    width: 120px;
    margin-right: 20px;
  }
  
  .blog .blog-author h4 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0px;
    padding: 0;
    color: #1e4356;
  }
  
  .blog .blog-author .social-links {
    margin: 0 10px 10px 0;
  }
  
  .blog .blog-author .social-links a {
    color: rgba(30, 67, 86, 0.5);
    margin-right: 5px;
  }
  
  .blog .blog-author p {
    font-style: italic;
    color: #b7b7b7;
  }
  
  .blog .blog-comments {
    margin-bottom: 30px;
  }
  
  .blog .blog-comments .comments-count {
    font-weight: bold;
  }
  
  .blog .blog-comments .comment {
    margin-top: 30px;
    position: relative;
  }
  
  .blog .blog-comments .comment .comment-img {
    margin-right: 14px;
  }
  
  .blog .blog-comments .comment .comment-img img {
    width: 60px;
  }
  
  .blog .blog-comments .comment h5 {
    font-size: 16px;
    margin-bottom: 2px;
  }
  
  .blog .blog-comments .comment h5 a {
    font-weight: bold;
    color: #444;
    transition: 0.3s;
  }
  
  .blog .blog-comments .comment h5 a:hover {
    color: #68A4C4;
  }
  
  .blog .blog-comments .comment h5 .reply {
    padding-left: 10px;
    color: #1e4356;
  }
  
  .blog .blog-comments .comment h5 .reply i {
    font-size: 20px;
  }
  
  .blog .blog-comments .comment time {
    display: block;
    font-size: 14px;
    color: #2b607c;
    margin-bottom: 5px;
  }
  
  .blog .blog-comments .comment.comment-reply {
    padding-left: 40px;
  }
  
  .blog .blog-comments .reply-form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .blog-comments .reply-form h4 {
    font-weight: bold;
    font-size: 22px;
  }
  
  .blog .blog-comments .reply-form p {
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form input {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form input:focus {
    box-shadow: none;
    border-color: #b1d0e1;
  }
  
  .blog .blog-comments .reply-form textarea {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form textarea:focus {
    box-shadow: none;
    border-color: #b1d0e1;
  }
  
  .blog .blog-comments .reply-form .form-group {
    margin-bottom: 25px;
  }
  
  .blog .blog-comments .reply-form .btn-primary {
    border-radius: 4px;
    padding: 10px 20px;
    border: 0;
    background-color: #1e4356;
  }
  
  .blog .blog-comments .reply-form .btn-primary:hover {
    background-color: #255269;
  }
  
  .blog .blog-pagination {
    color: #387ea2;
  }
  
  .blog .blog-pagination ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .blog .blog-pagination li {
    margin: 0 5px;
    transition: 0.3s;
  }
  
  .blog .blog-pagination li a {
    color: #1e4356;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .blog .blog-pagination li.active, .blog .blog-pagination li:hover {
    background: #68A4C4;
  }
  
  .blog .blog-pagination li.active a, .blog .blog-pagination li:hover a {
    color: #fff;
  }
  /*--------------------------------------------------------------
  # Blog
  --------------------------------------------------------------*/
  .blog .row:after{
     content: "."; 
     visibility: hidden; 
     display: block; 
     height: 0; 
     clear: both;
  
  }
  
  
  .blog .card{
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    max-width: 300px;
  }
  .blog .card img{
   /* overflow: hidden;
    height: 50px;
    padding: 2px;*/
    width: 100%;
      height: 5vw;
      object-fit: cover;
  }
  .blog .sidebar {
    
    padding: 30px 30px 30px 30px;
    box-sizing: border-box;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    top: 80px;
  
  }
  
  .blog .sidebar .sidebar-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 15px 0;
    color: #1e4356;
    position: relative;
  }
  .blog .abtlistgrp .card{
    padding: 0 0 0 0;
  }
  .blog .abtlistgrp .card {
   padding: 0px 0px 0px 0px;
   box-sizing: border-box;
   margin: 20px 0 0 0;
   border-radius: 0;
   box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
   max-width: 500px;
  }
  .blog .abtlistgrp .card .btn-submit {
   background-color: #011520;
   color: #ffffff;
   border-radius: 0;
   max-width: 500px;
  }
  .abtlistgrp .card .list-group i{
   margin-right: 20px;
  }
  .blog .abtlistgrp .card .list-group .btn {
   padding: .5rem 1rem;
   font-size: 1.25rem;
   border-radius: .3rem;
   width: 100%;
 }
.accordion {
    max-width: auto;
  }
  .accordion-item {
    list-style: none;
  }
  .accordion-toggle {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    padding: 1em;
  }
  .accordion-toggle:hover {
    background-color: #e7f1ff;
  }
  .accordion-toggle h3 {
    margin: 0;
  }
  .accordion-content {
    background-color: #eee;
    padding: 0.5em;
  }


  /*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
    padding: 40px 0 20px 0;
  }
  .blog hr {
    height: 1px;
    margin-left: 0px;
    margin-bottom:15px;
  }
  .blog .hr-warning{
    background-image: -webkit-linear-gradient(left, rgba(210,105,30,.8), rgba(210,105,30,.6), rgba(0,0,0,0));
  }
  .blog .hr-success{
    background-image: -webkit-linear-gradient(left, rgba(15,157,88,.8), rgba(15, 157, 88,.6), rgba(0,0,0,0));
  }
  .blog .hr-primary{
    background-image: -webkit-linear-gradient(left, rgba(66,133,244,.8), rgba(66, 133, 244,.6), rgba(0,0,0,0));
  }
  .blog .hr-danger{
    background-image: -webkit-linear-gradient(left, rgba(244,67,54,.8), rgba(244,67,54,.6), rgba(0,0,0,0));
  }
  .blog .entry {
    padding: 30px;
    margin-bottom: 60px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .entry .entry-img {
    max-height: 440px;
    margin: -30px -30px 20px -30px;
    overflow: hidden;
  }
  
  .blog .entry .entry-title {
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0 0 20px 0;
  }
  
  .blog .entry .entry-title a {
    color: #1e4356;
    transition: 0.3s;
  }
  
  .blog .entry .entry-title a:hover {
    color: #68A4C4;
  }
  
  .blog .entry .entry-meta {
    margin-bottom: 15px;
    color: #72afce;
  }
  
  .blog .entry .entry-meta ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  
  .blog .entry .entry-meta ul li + li {
    padding-left: 20px;
  }
  
  .blog .entry .entry-meta i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0;
  }
  
  .blog .entry .entry-meta a {
    color: #777777;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
  }
  
  .blog .entry .entry-content p {
    font-size: 12px;
    line-height: 24px;
    text-align: justify;
  }
  
  .blog .entry .entry-content .read-more {
    text-align-last: right;
  }
  
  .blog .entry .entry-content .read-more a {
    display: inline-block;
    background: #68A4C4;
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
  }
  
  .blog .entry .entry-content .read-more a:hover {
    background: #7aafcb;
  }
  
  
  .blog .apply-filter {
    text-align-last: center;
  }
  
  .blog .apply-filter a {
    display: inline-block;
    background: #68A4C4;
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
    text-decoration: none;
  }
  
  .blog .apply-filter a:hover {
    background: #7aafcb;
  }
  
  
  
  
  
  .blog .entry .entry-content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
  }
  
  .blog .entry .entry-content blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
  }
  
  .blog .entry .entry-content blockquote p {
    color: #444;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
  }
  
  .blog .entry .entry-content blockquote::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #1e4356;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .blog .entry .entry-footer {
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
  }
  
  .blog .entry .entry-footer i {
    color: #4c99c1;
    display: inline;
  }
  
  .blog .entry .entry-footer a {
    color: #255269;
    transition: 0.3s;
  }
  
  .blog .entry .entry-footer a:hover {
    color: #68A4C4;
  }
  
  .blog .entry .entry-footer .cats {
    list-style: none;
    display: inline;
    padding: 0 10px 0 0;
    font-size: 14px;
  }
  
  .blog .entry .entry-footer .cats li {
    display: inline-block;
  }
  
  .blog .entry .entry-footer .tags {
    list-style: none;
    display: inline;
    padding: 0 10px 0 0;
    font-size: 14px;
  }
  
  .blog .entry .entry-footer .tags li {
    display: inline-block;
  }
  
  .blog .entry .entry-footer .tags li + li::before {
    padding-right: 6px;
    color: #6c757d;
    content: ",";
  }
  
  .blog .entry .entry-footer .share {
    font-size: 16px;
  }
  
  .blog .entry .entry-footer .share i {
    padding-left: 5px;
  }
  
  .blog .entry-single {
    margin-bottom: 30px;
  }
  
  .blog .blog-author {
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .blog-author img {
    width: 120px;
    margin-right: 20px;
  }
  
  .blog .blog-author h4 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0px;
    padding: 0;
    color: #1e4356;
  }
  
  .blog .blog-author .social-links {
    margin: 0 10px 10px 0;
  }
  
  .blog .blog-author .social-links a {
    color: rgba(30, 67, 86, 0.5);
    margin-right: 5px;
  }
  
  .blog .blog-author p {
    font-style: italic;
    color: #b7b7b7;
  }
  
  .blog .blog-comments {
    margin-bottom: 30px;
  }
  
  .blog .blog-comments .comments-count {
    font-weight: bold;
  }
  
  .blog .blog-comments .comment {
    margin-top: 30px;
    position: relative;
  }
  
  .blog .blog-comments .comment .comment-img {
    margin-right: 14px;
  }
  
  .blog .blog-comments .comment .comment-img img {
    width: 60px;
  }
  
  .blog .blog-comments .comment h5 {
    font-size: 16px;
    margin-bottom: 2px;
  }
  
  .blog .blog-comments .comment h5 a {
    font-weight: bold;
    color: #444;
    transition: 0.3s;
  }
  
  .blog .blog-comments .comment h5 a:hover {
    color: #68A4C4;
  }
  
  .blog .blog-comments .comment h5 .reply {
    padding-left: 10px;
    color: #1e4356;
  }
  
  .blog .blog-comments .comment h5 .reply i {
    font-size: 20px;
  }
  
  .blog .blog-comments .comment time {
    display: block;
    font-size: 14px;
    color: #2b607c;
    margin-bottom: 5px;
  }
  
  .blog .blog-comments .comment.comment-reply {
    padding-left: 40px;
  }
  
  .blog .blog-comments .reply-form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .blog-comments .reply-form h4 {
    font-weight: bold;
    font-size: 22px;
  }
  
  .blog .blog-comments .reply-form p {
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form input {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form input:focus {
    box-shadow: none;
    border-color: #b1d0e1;
  }
  
  .blog .blog-comments .reply-form textarea {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form textarea:focus {
    box-shadow: none;
    border-color: #b1d0e1;
  }
  
  .blog .blog-comments .reply-form .form-group {
    margin-bottom: 25px;
  }
  
  .blog .blog-comments .reply-form .btn-primary {
    border-radius: 4px;
    padding: 10px 20px;
    border: 0;
    background-color: #1e4356;
  }
  
  .blog .blog-comments .reply-form .btn-primary:hover {
    background-color: #255269;
  }
  
  .blog .blog-pagination {
    color: #387ea2;
  }
  
  .blog .blog-pagination ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .blog .blog-pagination li {
    margin: 0 5px;
    transition: 0.3s;
  }
  
  .blog .blog-pagination li a {
    color: #1e4356;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .blog .blog-pagination li.active, .blog .blog-pagination li:hover {
    background: #68A4C4;
  }
  
  .blog .blog-pagination li.active a, .blog .blog-pagination li:hover a {
    color: #fff;
  }
  /*--------------------------------------------------------------
  # Blog
  --------------------------------------------------------------*/
  .blog .row:after{
     content: "."; 
     visibility: hidden; 
     display: block; 
     height: 0; 
     clear: both;
  
  }
  /* .blog .card img {
    width: 100%;
    height: 5vw;
    object-fit: cover;
  } */
  
  .blog .card{
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    max-width: 300px;
  }
  .blog .card .card-img-top {
    /* overflow: hidden;
    height: 50px;
    padding: 2px; */
    width: 100%;
    height: 100%;
    object-fit: inherit;
    padding: 2px;
  }


  .blog .sidebar {
    
    padding: 30px 30px 30px 30px;
    box-sizing: border-box;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    top: 80px;
  
  }
  
  .blog .sidebar .sidebar-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 15px 0;
    color: #1e4356;
    position: relative;
  }
  .blog .abtlistgrp .card{
    padding: 0 0 0 0;
  }
  .blog .abtlistgrp .card {
   padding: 0px 0px 0px 0px;
   box-sizing: border-box;
   margin: 20px 0 0 0;
   border-radius: 0;
   box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
   max-width: 500px;
  }
  .blog .abtlistgrp .card .btn-submit {
   background-color: #011520;
   color: #ffffff;
   border-radius: 0;
   max-width: 500px;
  }
  .abtlistgrp .card .list-group i{
   margin-right: 20px;
  }
  .blog .abtlistgrp .card .list-group .btn {
   padding: .5rem 1rem;
   font-size: 1.25rem;
   border-radius: .3rem;
   width: 100%;
  }
#hero-no-slide-dst {
    width: 100%;
    /* height: 40vh; */

    /* max-height: 400px; */
    overflow: hidden;
    position: relative;
    text-align: center;
   background:  url(/static/media/DS_New_Img.9adbaffd.jpg) center top no-repeat;
   /*background: #0a1f25;*/
  }
  
  #hero-no-slide-dst::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: url(/static/media/DS_New_Img.9adbaffd.jpg) center top no-repeat;
    /*background: #0a1f25; */
   
  }
  
  #hero-no-slide-dst h2 {
    color: #fff;
    margin-top: 120px;
    margin-bottom: 12px;
    font-size: 48px;
    font-weight: 700;
  }

  #hero-no-slide-dst h1 {
    color: #fff;
    margin-top: 120px;
    margin-bottom: 12px;
    font-size: 48px;
    font-weight: 700;
}
  
  #hero-no-slide-dst p {
    color: #fff;
  }
  
  #hero-no-slide-dst .btn-get-started {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    background: #94c045;
    /* border: 2px solid #94c045; */
  }
  
  /* #hero-no-slider .btn-get-started:hover {
    background: #94c045;
    color: #fff;
    text-decoration: none;
  } */

  #hero-no-slide-dst .btn-get-started:hover {
    /* background: #94c045; */
    color: #01081a;
    text-decoration: none;
  }
  
  @media (max-width: 575px) {
    #hero-no-slide-dst h2 {
      font-size: 30px;
    }
  }
  
  @media screen and (min-width: 1400px) {
    #hero-no-slide-dst {
      margin: 0 auto;
      width: 1400px;
    }
  }

.blog #journalcard{
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    margin: 0 0 0 0;
    box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
}
.blog .card #journalpfimg_p {
height: 100%;
width: 100%;
object-fit: contain;
overflow: hidden;
}
.blog .card-text{
    margin-top: 20px;
}
@media (max-width: 991px){
    .blog #journalcard{
        padding: 10px 10px 10px 10px;
        box-sizing: border-box;
        margin: 0 0 0 0;
        box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
    }
    .blog .card #journalpfimg {
      
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    
    .blog .card .card-body .card-text {
        margin: 20px;
        font-size: 75%;
    }
}

#sidebar-accordion {
    margin-bottom: -1px;
    background-color: #194350;
    color: #fff;
    border: 1px solid #ccc;
}
#sidebar-accordion .accordion-text{
    border: 1px solid #194350;
}

#sidebar-accordion {
    margin-bottom: -1px;
    background-color: #194350;
    color: #fff;
    border: 1px solid #ccc;
}
#sidebar-accordion .accordion-text{
    border: 1px solid #194350;
}

#hero-no-slide-dlll {
    width: 100%;
    /* height: 40vh; */

    /* max-height: 400px; */
    overflow: hidden;
    position: relative;
    text-align: center;
    background:  url(/static/media/DS_LLL_New_Img.eefa940d.jpg) center top no-repeat;
   /* background:  url("../assets/images/DS_New_Img.jpg") center top no-repeat; */
   /*background: #0a1f25;*/
  }
  
  #hero-no-slide-dlll::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background:  url(/static/media/DS_LLL_New_Img.eefa940d.jpg) center top no-repeat;
    /* background: url("../assets/images/DS_New_Img.jpg") center top no-repeat; */
    /*background: #0a1f25; */
   
  }
  
  #hero-no-slide-dlll h2 {
    color: #fff;
    margin-top: 120px;
    margin-bottom: 12px;
    font-size: 48px;
    font-weight: 700;
  }
  
  #hero-no-slide-dlll p {
    color: #fff;
  }
  
  #hero-no-slide-dlll .btn-get-started {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    background: #94c045;
    /* border: 2px solid #94c045; */
  }
  
  /* #hero-no-slider .btn-get-started:hover {
    background: #94c045;
    color: #fff;
    text-decoration: none;
  } */

  #hero-no-slide-dlll .btn-get-started:hover {
    /* background: #94c045; */
    color: #01081a;
    text-decoration: none;
  }
  
  @media (max-width: 575px) {
    #hero-no-slide-dlll h2 {
      font-size: 30px;
    }
  }
  
  @media screen and (min-width: 1400px) {
    #hero-no-slide-dlll {
      margin: 0 auto;
      width: 1400px;
    }
  }

.blog #journalcard{
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    margin: 0 0 0 0;
    box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
}
.blog .card #journalpfimg_p {
height: 100%;
width: 100%;
object-fit: contain;
overflow: hidden;

}
.blog .card-text{
    margin-top: 20px;
}
@media (max-width: 991px){
    .blog #journalcard{
        padding: 10px 10px 10px 10px;
        box-sizing: border-box;
        margin: 0 0 0 0;
        box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
    }
    .blog .card #journalpfimg {
      
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    
    .blog .card .card-body .card-text {
        margin: 20px;
        font-size: 75%;
    }
}

#sidebar-accordion {
    margin-bottom: -1px;
    background-color: #194350;
    color: #fff;
    border: 1px solid #ccc;
}
#sidebar-accordion .accordion-text{
    border: 1px solid #194350;
}
.blog .card #journalpfimg_p {
    height: 100%;
    width: 100%;
    object-fit: contain;
    overflow: hidden;
    
    }
.refertag a {
    color: #4d8a17;
}

.refertag a:hover {
    color:#6ba439;
}

.refertag p {
    line-height: normal;
    margin-bottom: 0.2rem;
}

.refertag p a {
    line-height: normal;
    margin-bottom: 0.1rem;
}
#hero-no-slide-dsdsm {
    width: 100%;
    /* height: 40vh; */

    /* max-height: 400px; */
    overflow: hidden;
    position: relative;
    text-align: center;
    background:  url(/static/media/DSM_New_Img.8fd454b6.jpg) center top no-repeat;
   /* background:  url("../assets/images/DS_New_Img.jpg") center top no-repeat; */
   /*background: #0a1f25;*/
  }
  
  #hero-no-slide-dsdsm::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background:  url(/static/media/DSM_New_Img.8fd454b6.jpg) center top no-repeat;
    /* background: url("../assets/images/DS_New_Img.jpg") center top no-repeat; */
    /*background: #0a1f25; */
   
  }
  
  #hero-no-slide-dsdsm h2 {
    color: #fff;
    margin-top: 120px;
    margin-bottom: 12px;
    font-size: 48px;
    font-weight: 700;
  }
  
  #hero-no-slide-dsdsm p {
    color: #fff;
  }
  
  #hero-no-slide-dsdsm .btn-get-started {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    background: #94c045;
    /* border: 2px solid #94c045; */
  }
  
  /* #hero-no-slider .btn-get-started:hover {
    background: #94c045;
    color: #fff;
    text-decoration: none;
  } */

  #hero-no-slide-dsdsm .btn-get-started:hover {
    /* background: #94c045; */
    color: #01081a;
    text-decoration: none;
  }
  
  @media (max-width: 575px) {
    #hero-no-slide-dsdsm h2 {
      font-size: 30px;
    }
  }
  
  @media screen and (min-width: 1400px) {
    #hero-no-slide-dsdsm {
      margin: 0 auto;
      width: 1400px;
    }
  }

.blog #journalcard{
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    margin: 0 0 0 0;
    box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
}
.blog .card #journalpfimg_p {
height: 100%;
width: 100%;
object-fit: contain;
overflow: hidden;

}
.blog .card-text{
    margin-top: 20px;
}
@media (max-width: 991px){
    .blog #journalcard{
        padding: 10px 10px 10px 10px;
        box-sizing: border-box;
        margin: 0 0 0 0;
        box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
    }
    .blog .card #journalpfimg {
      
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    
    .blog .card .card-body .card-text {
        margin: 20px;
        font-size: 75%;
    }
}

#sidebar-accordion {
    margin-bottom: -1px;
    background-color: #194350;
    color: #fff;
    border: 1px solid #ccc;
}
#sidebar-accordion .accordion-text{
    border: 1px solid #194350;
}

#sidebar-accordion {
    margin-bottom: -1px;
    background-color: #194350;
    color: #fff;
    border: 1px solid #ccc;
}
#sidebar-accordion .accordion-text{
    border: 1px solid #194350;
}
.blog .card #journalpfimg_p {
    height: 100%;
    width: 100%;
    object-fit: contain;
    overflow: hidden;
    
    }
#hero-no-slide-dsms {
    width: 100%;
    /* height: 40vh; */

    /* max-height: 400px; */
    overflow: hidden;
    position: relative;
    text-align: center;
    background:  url(/static/media/DS_MS_New_Img.55136999.jpg) center top no-repeat;
   /* background:  url("../assets/images/DS_New_Img.jpg") center top no-repeat; */
   /*background: #0a1f25;*/
  }
  
  #hero-no-slide-dsms::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background:  url(/static/media/DS_MS_New_Img.55136999.jpg) center top no-repeat;
    /* background: url("../assets/images/DS_New_Img.jpg") center top no-repeat; */
    /*background: #0a1f25; */
   
  }
  
  #hero-no-slide-dsms h2 {
    color: #fff;
    margin-top: 120px;
    margin-bottom: 12px;
    font-size: 48px;
    font-weight: 700;
  }
  
  #hero-no-slide-dsms p {
    color: #fff;
  }
  
  #hero-no-slide-dsms .btn-get-started {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    background: #94c045;
    /* border: 2px solid #94c045; */
  }
  
  /* #hero-no-slider .btn-get-started:hover {
    background: #94c045;
    color: #fff;
    text-decoration: none;
  } */

  #hero-no-slide-dsms .btn-get-started:hover {
    /* background: #94c045; */
    color: #01081a;
    text-decoration: none;
  }
  
  @media (max-width: 575px) {
    #hero-no-slide-dsms h2 {
      font-size: 30px;
    }
  }
  
  @media screen and (min-width: 1400px) {
    #hero-no-slide-dsms {
      margin: 0 auto;
      width: 1400px;
    }
  }

.blog #journalcard{
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    margin: 0 0 0 0;
    box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
}
.blog .card #journalpfimg_p {
height: 100%;
width: 100%;
object-fit: contain;
overflow: hidden;

}
.blog .card-text{
    margin-top: 20px;
}
@media (max-width: 991px){
    .blog #journalcard{
        padding: 10px 10px 10px 10px;
        box-sizing: border-box;
        margin: 0 0 0 0;
        box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
    }
    .blog .card #journalpfimg {
      
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    
    .blog .card .card-body .card-text {
        margin: 20px;
        font-size: 75%;
    }
}

#sidebar-accordion {
    margin-bottom: -1px;
    background-color: #194350;
    color: #fff;
    border: 1px solid #ccc;
}
#sidebar-accordion .accordion-text{
    border: 1px solid #194350;
}

#sidebar-accordion {
    margin-bottom: -1px;
    background-color: #194350;
    color: #fff;
    border: 1px solid #ccc;
}
#sidebar-accordion .accordion-text{
    border: 1px solid #194350;
}
.blog .card #journalpfimg_p {
    height: 100%;
    width: 100%;
    object-fit: contain;
    overflow: hidden;
    
    }
#hero-no-slide-dsce {
    width: 100%;
    /* height: 40vh; */

    /* max-height: 400px; */
    overflow: hidden;
    position: relative;
    text-align: center;
    background:  url(/static/media/DS-Journal-of-Multidisciplinary-Banner.81fad675.jpg) center top no-repeat;
   /* background:  url("../assets/images/DS_New_Img.jpg") center top no-repeat; */
   /*background: #0a1f25;*/
  }
  
  #hero-no-slide-dsce::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background:  url(/static/media/DS-Journal-of-Multidisciplinary-Banner.81fad675.jpg) center top no-repeat;
    /* background: url("../assets/images/DS_New_Img.jpg") center top no-repeat; */
    /*background: #0a1f25; */
   
  }
  
  #hero-no-slide-dsce h2 {
    color: #fff;
    margin-top: 120px;
    margin-bottom: 12px;
    font-size: 48px;
    font-weight: 700;
  }
  
  #hero-no-slide-dsce p {
    color: #fff;
  }
  
  #hero-no-slide-dsce .btn-get-started {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    background: #94c045;
    /* border: 2px solid #94c045; */
  }
  
  /* #hero-no-slider .btn-get-started:hover {
    background: #94c045;
    color: #fff;
    text-decoration: none;
  } */

  #hero-no-slide-dsce .btn-get-started:hover {
    /* background: #94c045; */
    color: #01081a;
    text-decoration: none;
  }
  
  @media (max-width: 575px) {
    #hero-no-slide-dsce h2 {
      font-size: 30px;
    }
  }
  
  @media screen and (min-width: 1400px) {
    #hero-no-slide-dsce {
      margin: 0 auto;
      width: 1400px;
    }
  }

.blog #journalcard{
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    margin: 0 0 0 0;
    box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
}
.blog .card #journalpfimg_p {
height: 100%;
width: 100%;
object-fit: contain;
overflow: hidden;

}
.blog .card-text{
    margin-top: 20px;
}
@media (max-width: 991px){
    .blog #journalcard{
        padding: 10px 10px 10px 10px;
        box-sizing: border-box;
        margin: 0 0 0 0;
        box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
    }
    .blog .card #journalpfimg {
      
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    
    .blog .card .card-body .card-text {
        margin: 20px;
        font-size: 75%;
    }
}

#sidebar-accordion {
    margin-bottom: -1px;
    background-color: #194350;
    color: #fff;
    border: 1px solid #ccc;
}
#sidebar-accordion .accordion-text{
    border: 1px solid #194350;
}

.blog .card #journalpfimg_p {
    height: 100%;
    width: 100%;
    object-fit: contain;
    overflow: hidden;
    
    }
#hero-no-slide-dsair {
    width: 100%;
    /* height: 40vh; */

    /* max-height: 400px; */
    overflow: hidden;
    position: relative;
    text-align: center;
    background:  url(/static/media/DS_AIR_New_Img.1bfc7560.jpg) center top no-repeat;
   /* background:  url("../assets/images/DS_New_Img.jpg") center top no-repeat; */
   /*background: #0a1f25;*/
  }
  
  #hero-no-slide-dsair::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background:  url(/static/media/DS_AIR_New_Img.1bfc7560.jpg) center top no-repeat;
    /* background: url("../assets/images/DS_New_Img.jpg") center top no-repeat; */
    /*background: #0a1f25; */
   
  }
  
  #hero-no-slide-dsair h2 {
    color: #fff;
    margin-top: 120px;
    margin-bottom: 12px;
    font-size: 48px;
    font-weight: 700;
  }
  
  #hero-no-slide-dsair p {
    color: #fff;
  }
  
  #hero-no-slide-dsair .btn-get-started {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    background: #94c045;
    /* border: 2px solid #94c045; */
  }
  
  /* #hero-no-slider .btn-get-started:hover {
    background: #94c045;
    color: #fff;
    text-decoration: none;
  } */

  #hero-no-slide-dsair .btn-get-started:hover {
    /* background: #94c045; */
    color: #01081a;
    text-decoration: none;
  }
  
  @media (max-width: 575px) {
    #hero-no-slide-dsair h2 {
      font-size: 30px;
    }
  }
  
  @media screen and (min-width: 1400px) {
    #hero-no-slide-dsair {
      margin: 0 auto;
      width: 1400px;
    }
  }

.blog #journalcard{
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    margin: 0 0 0 0;
    box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
}
.blog .card #journalpfimg_p {
height: 100%;
width: 100%;
object-fit: contain;
overflow: hidden;

}
.blog .card-text{
    margin-top: 20px;
}
@media (max-width: 991px){
    .blog #journalcard{
        padding: 10px 10px 10px 10px;
        box-sizing: border-box;
        margin: 0 0 0 0;
        box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
    }
    .blog .card #journalpfimg {
      
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    
    .blog .card .card-body .card-text {
        margin: 20px;
        font-size: 75%;
    }
}

#sidebar-accordion {
    margin-bottom: -1px;
    background-color: #194350;
    color: #fff;
    border: 1px solid #ccc;
}
#sidebar-accordion .accordion-text{
    border: 1px solid #194350;
}

#sidebar-accordion {
    margin-bottom: -1px;
    background-color: #194350;
    color: #fff;
    border: 1px solid #ccc;
}
#sidebar-accordion .accordion-text{
    border: 1px solid #194350;
}
.blog .card #journalpfimg_p {
    height: 100%;
    width: 100%;
    object-fit: contain;
    overflow: hidden;
    
    }
#hero-no-slide-dscy {
    width: 100%;
    /* height: 40vh; */

    /* max-height: 400px; */
    overflow: hidden;
    position: relative;
    text-align: center;
    background:  url(/static/media/DS_CY_New_Img.e8703a4e.jpg) center top no-repeat;
   /* background:  url("../assets/images/DS_New_Img.jpg") center top no-repeat; */
   /*background: #0a1f25;*/
  }
  
  #hero-no-slide-dscy::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background:  url(/static/media/DS_CY_New_Img.e8703a4e.jpg) center top no-repeat;
    /* background: url("../assets/images/DS_New_Img.jpg") center top no-repeat; */
    /*background: #0a1f25; */
   
  }
  
  #hero-no-slide-dscy h2 {
    color: #fff;
    margin-top: 120px;
    margin-bottom: 12px;
    font-size: 48px;
    font-weight: 700;
  }
  
  #hero-no-slide-dscy p {
    color: #fff;
  }
  
  #hero-no-slide-dscy .btn-get-started {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    background: #94c045;
    /* border: 2px solid #94c045; */
  }
  
  /* #hero-no-slider .btn-get-started:hover {
    background: #94c045;
    color: #fff;
    text-decoration: none;
  } */

  #hero-no-slide-dscy .btn-get-started:hover {
    /* background: #94c045; */
    color: #01081a;
    text-decoration: none;
  }
  
  @media (max-width: 575px) {
    #hero-no-slide-dscy h2 {
      font-size: 30px;
    }
  }
  
  @media screen and (min-width: 1400px) {
    #hero-no-slide-dscy {
      margin: 0 auto;
      width: 1400px;
    }
  }

.blog #journalcard{
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    margin: 0 0 0 0;
    box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
}
.blog .card #journalpfimg_p {
height: 100%;
width: 100%;
object-fit: contain;
overflow: hidden;

}
.blog .card-text{
    margin-top: 20px;
}
@media (max-width: 991px){
    .blog #journalcard{
        padding: 10px 10px 10px 10px;
        box-sizing: border-box;
        margin: 0 0 0 0;
        box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
    }
    .blog .card #journalpfimg {
      
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    
    .blog .card .card-body .card-text {
        margin: 20px;
        font-size: 75%;
    }
}

#sidebar-accordion {
    margin-bottom: -1px;
    background-color: #194350;
    color: #fff;
    border: 1px solid #ccc;
}
#sidebar-accordion .accordion-text{
    border: 1px solid #194350;
}

#sidebar-accordion {
    margin-bottom: -1px;
    background-color: #194350;
    color: #fff;
    border: 1px solid #ccc;
}
#sidebar-accordion .accordion-text{
    border: 1px solid #194350;
}
.blog .card #journalpfimg_p {
    height: 100%;
    width: 100%;
    object-fit: contain;
    overflow: hidden;
    
    }
#hero-no-slide-dsrls {
    width: 100%;
    /* height: 40vh; */

    /* max-height: 400px; */
    overflow: hidden;
    position: relative;
    text-align: center;
    background:  url(/static/media/DS_RLS_New_Img.2c6fce94.jpg) center top no-repeat;
   /* background:  url("../assets/images/DS_New_Img.jpg") center top no-repeat; */
   /*background: #0a1f25;*/
  }
  
  #hero-no-slide-dsrls::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background:  url(/static/media/DS_RLS_New_Img.2c6fce94.jpg) center top no-repeat;
    /* background: url("../assets/images/DS_New_Img.jpg") center top no-repeat; */
    /*background: #0a1f25; */
   
  }
  
  #hero-no-slide-dsrls h2 {
    color: #fff;
    margin-top: 120px;
    margin-bottom: 12px;
    font-size: 48px;
    font-weight: 700;
  }
  
  #hero-no-slide-dsrls p {
    color: #fff;
  }
  
  #hero-no-slide-dsrls .btn-get-started {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    background: #94c045;
    /* border: 2px solid #94c045; */
  }
  
  /* #hero-no-slider .btn-get-started:hover {
    background: #94c045;
    color: #fff;
    text-decoration: none;
  } */

  #hero-no-slide-dsrls .btn-get-started:hover {
    /* background: #94c045; */
    color: #01081a;
    text-decoration: none;
  }
  
  @media (max-width: 575px) {
    #hero-no-slide-dsrls h2 {
      font-size: 30px;
    }
  }
  
  @media screen and (min-width: 1400px) {
    #hero-no-slide-dsrls {
      margin: 0 auto;
      width: 1400px;
    }
  }

.blog #journalcard{
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    margin: 0 0 0 0;
    box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
}
.blog .card #journalpfimg_p {
height: 100%;
width: 100%;
object-fit: contain;
overflow: hidden;

}
.blog .card-text{
    margin-top: 20px;
}
@media (max-width: 991px){
    .blog #journalcard{
        padding: 10px 10px 10px 10px;
        box-sizing: border-box;
        margin: 0 0 0 0;
        box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
    }
    .blog .card #journalpfimg {
      
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    
    .blog .card .card-body .card-text {
        margin: 20px;
        font-size: 75%;
    }
}

#sidebar-accordion {
    margin-bottom: -1px;
    background-color: #194350;
    color: #fff;
    border: 1px solid #ccc;
}
#sidebar-accordion .accordion-text{
    border: 1px solid #194350;
}
#sidebar-accordion {
    margin-bottom: -1px;
    background-color: #194350;
    color: #fff;
    border: 1px solid #ccc;
}
#sidebar-accordion .accordion-text{
    border: 1px solid #194350;
}

#sidebar-accordion {
    margin-bottom: -1px;
    background-color: #194350;
    color: #fff;
    border: 1px solid #ccc;
}
#sidebar-accordion .accordion-text{
    border: 1px solid #194350;
}
.accordion{
    cursor: pointer;
    padding: 0;
}
.accordion-icon{
    cursor: pointer;
    float: right;
    font-weight: 600;
}

.search-result .title h3 {
    font-size: 15px;
    margin: 0 0 15px;
    color: #333;
    float:left;
    text-align: justify;
}
.search-result .content {
    font-size: 12px;
    color: #333;
    float:left;
    text-align: justify;
}
.search-result .content a {
    font-size: 12px;
    float:left;
    text-align:justify;
    padding-right: 10px;
   
}
.well-header{
    border: 0;
    padding: 20px;
    min-height: 63px;
    background: #fff;
    box-shadow: none;
    border-radius: 3px;
    position: relative;
    max-height: 100000px;
    /* border-bottom: 2px solid #ccc; */
    transition: max-height 0.5s ease;
    -o-transition: max-height 0.5s ease;
    -ms-transition: max-height 0.5s ease;
    -moz-transition: max-height 0.5s ease;
    -webkit-transition: max-height 0.5s ease;
}
.well {
    border: 0;
    padding: 20px;
    min-height: 63px;
    background: #fff;
    box-shadow: none;
    border-radius: 3px;
    position: relative;
    
    /* border-bottom: 2px solid #ccc; */
    transition: max-height 0.5s ease;
    -o-transition: max-height 0.5s ease;
    -ms-transition: max-height 0.5s ease;
    -moz-transition: max-height 0.5s ease;
    -webkit-transition: max-height 0.5s ease;
}
.form-control {
    height: 45px;
    padding: 10px;
    font-size: 16px;
    box-shadow: none;
    border-radius: 0;
    position: relative;
}
.well .btn{
    transition: all .5s ease;
    margin-top: 0;
    width: 200px;
    height: 45px;
    border-radius: 0;
    color: #008080;
    font-weight: 600;
    background-color: #fff;
    border: 1px solid #194350;
    margin-bottom: 1em;
    
}
/*--------------------------------------------------------------
  # Hero No Slider Section
  --------------------------------------------------------------*/
  #main {
    margin-top: 0px;
  }
  #hero-no-slider {
    width: 100%;
    /* height: 40vh;  */
    max-height: 600px;
    overflow: hidden;
    position: relative;
    text-align: center;
   background:  url(/static/media/hero-bgimg.e252d7fe.jpg) center top no-repeat;
   /*background: #0a1f25;*/
  }
  
  #hero-no-slider::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/static/media/hero-bgimg.e252d7fe.jpg) center top no-repeat;
    /* background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(153, 123, 123, 0.5)), url("./assets/images/hero-bgimg.jpg") center top no-repeat; */
    /*background: #0a1f25; */
   
  }
  
  #hero-no-slider h2 {
    color: #fff;
    margin-top: 120px;
    margin-bottom: 12px;
    font-size: 48px;
    font-weight: 700;
    font-family: sans-serif;
  }
  
  #hero-no-slider h1 {
    color: #fff;
    margin-top: 120px;
    margin-bottom: 12px;
    font-size: 48px;
    font-weight: 700;
    font-family: sans-serif;
  }

  #hero-no-slider p {
    color: #fff;
  }
  
  #hero-no-slider .btn-get-started {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    background: #94c045;
    /* border: 2px solid #94c045; */
  }
  
  /* #hero-no-slider .btn-get-started:hover {
    background: #94c045;
    color: #fff;
    text-decoration: none;
  } */

  #hero-no-slider .btn-get-started:hover {
    /* background: #94c045; */
    color: #01081a;
    text-decoration: none;
  }
  
  @media (max-width: 575px) {
    #hero-no-slider h2 {
      font-size: 30px;
    }
  }
  
  @media screen and (min-width: 1400px) {

    #main {
    margin: 0 auto;
    width: 1400px;
    
    }
    #header .container-fluid {
      margin: 0 auto;
      width: 1400px;
      background: #fff;
      padding: 10px 0px 10px 0;
    }
    #hero-no-slider {
      margin: 0 auto;
      width: 1400px;
    }
    .breadcrumbs{
      margin: 0 auto;
      width: 1400px;
    }
  }
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
    font-family: "IBM Plex Sans", sans-serif;
    color: #444;
    background-color: #f4f8fa;
  }
  
  a {
    color: #4fa6d5;
    text-decoration: none;
  }
  
  a:hover {
    color: #45beff;
    text-decoration: none;
  }
  
  h1, h2, h3, h4, h5, h6, .font-primary {
    font-family: "Roboto", sans-serif;
  }
  
 
  /*--------------------------------------------------------------
  # SearchBar
  --------------------------------------------------------------*/
  @media (max-width: 991px) {
    .search-box{
    /* transform:translateX(-30px) */
    -webkit-transform:translateX(-30px) !important;
            transform:translateX(-30px) !important
    }
  }
  .mobile-search-toggle {
    color: rgba(30, 67, 86);
    font-size: 25px;
    padding: 10px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
  }
  .search-box{
    /* background: #1e4356; */
    background:#21494f;
    position: absolute;
    top:54px;
    right: 0.1%;
    width: 350px;
    height: 60px;
    box-shadow: 0 0 10px rgba(0,0,0, 0.5);
    border-top: 2px solid #f2ba49;
    display: none;
    z-index: 9999;
  }
  .search-box::before{
    content: "";
    position: absolute;
    top:-32px;
    right: 5px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid transparent;
    border-bottom: 14px solid #f2ba49;
  }
  .search-box input[type="text"]{
    width: 230px;
    height: 40px;
    padding: 5px 10px;
    margin-left: 23px;
    border-top: 2px solid #f2ba49;
    outline: none;
  }
  .search-box input[type="button"]{
    width: 70px;
    height: 40px;
    padding: 8px 0;
    background: #94c146;
    border: 1px solid #94c146;
    outline: none;
    cursor: pointer;
    color: #fff;
    margin-left: -5px;
    margin-top: 10px;
  }
  
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 25px;
    bottom: 15px;
    z-index: 99999;
    background: #94c045;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #392933;
    transition: all 0.4s;
  }
  
  .back-to-top i {
    font-size: 24px;
    color: #392933;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: #94c045;
    border-color: #ffffff;
    color: #fff;
  }
  .back-to-top i:hover {
    color: #fff;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 30px 10px;
    background-color: #fff;
  }
  
  .section-bg {
    background-color: #f3f8fa;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .section-title h2 {
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
  }
  
  .section-title h2::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }
  
  .section-title h2::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #68A4C4;
    bottom: 0;
    left: calc(50% - 20px);
  }
  
  .section-title p {
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  .breadcrumbs {
    padding: 15px 0;
    background-color: #ffff;
    min-height: 40px;
    border-bottom: 2px solid #f3f8fa;
  }
  
  .breadcrumbs h2 {
    font-size: 28px;
    font-weight: 300;
  }
  
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .breadcrumbs ol li + li {
    padding-left: 10px;
  }
  
  .breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #6c757d;
    content: "/";
  }
  
  @media (max-width: 768px) {
    .breadcrumbs .d-flex {
      display: block !important;
      font-size: 75%;
    }
    .breadcrumbs ol {
      display: block;
    }
    .breadcrumbs ol li {
      display: inline-block;
    }
  }
  
  
  /*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about{
  /* margin-top:10px; */
   margin-top:0px; /*!important */
}
.about h3 {
  font-weight: 400;
  font-size: 26px;
}

.about ul {
  list-style: none;
  padding: 0;
}

.about ul li {
  padding-bottom: 10px;
}

.about ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #68A4C4;
}

.about p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .container {
 /* box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);*/
  padding-bottom: 15px;
  background: #fff;
 /* transform: translateY(-10%);*/
}

.about .count-box {
  width: 100%;
}

.about .count-box i {
  display: block;
  font-size: 48px;
  color: #94c045;
  float: left;
  line-height: 0;
}

.about .count-box span {
  font-size: 28px;
  line-height: 24px;
  display: block;
  font-weight: 700;
  color: #646c55;
  margin-left: 60px;
}

.about .count-box p {
  padding: 5px 0 0 0;
  margin: 0 0 0 60px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #646c55;
}

.about .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #646c55;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  transition: ease-in-out 0.3s;
}

.about .count-box a:hover {
  color: #8b9578;
}

.about .content {
  font-size: 15px;
}

.about .content h3 {
  font-weight: 700;
  font-size: 24px;
  color: #3c4133;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
  padding-left: 28px;
  position: relative;
}

.about .content ul i {
  font-size: 24px;
  color: #94c045;
  position: absolute;
  left: 0;
  top: -2px;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(#94c045 50%, rgba(148, 192, 69, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.about .play-btn::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-40%) translateY(-50%);
          transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.about .play-btn::before {
  content: '';
  position: absolute;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 2s;
  animation: pulsate-btn 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(148, 192, 69, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.about .play-btn:hover::after {
  border-left: 15px solid #94c045;
  -webkit-transform: scale(20);
          transform: scale(20);
}

.about .play-btn:hover::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-40%) translateY(-50%);
          transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}

@-webkit-keyframes pulsate-btn {
  0% {
    -webkit-transform: scale(0.6, 0.6);
            transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    -webkit-transform: scale(0.6, 0.6);
            transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    opacity: 0;
  }
}
@media (min-width: 768px){
#main{
  margin-top: 0px;
}
}
@media (min-width: 576px){
#main{
    margin-top: 0px;
  }
}
/*--------------------------------------------------------------
# Our Skills
--------------------------------------------------------------*/
.skills {
    padding: 60px 0;
    margin-top: 90px;
  }
  
  .skills .progress {
    height: 35px;
    margin-bottom: 10px;
  }
  
  .skills .progress .skill {
    line-height: 35px;
    padding: 0;
    margin: 0 0 0 20px;
    text-transform: uppercase;
  }
  
  .skills .progress .skill .val {
    float: right;
    font-style: normal;
    margin: 0 20px 0 0;
  }
  
  .skills .progress-bar {
    width: 1px;
    text-align: left;
    transition: .9s;
  }

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us .container {
    box-shadow: 0 5px 25px 0 rgba(214, 215, 216, 0.6);
    background: #fff;
  }
  
  .why-us .icon-box + .icon-box {
    margin-top: 50px;
  }
  
  .why-us .icon-box .icon {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    background: #f3f8fa;
    border-radius: 6px;
    transition: 0.5s;
  }
  
  .why-us .icon-box .icon i {
    color: #68A4C4;
    font-size: 32px;
  }
  
  .why-us .icon-box:hover .icon {
    background: #68A4C4;
  }
  
  .why-us .icon-box:hover .icon i {
    color: #fff;
  }
  
  .why-us .icon-box .title {
    margin-left: 95px;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 18px;
    text-transform: uppercase;
  }
  
  .why-us .icon-box .title a {
    color: #343a40;
    transition: 0.3s;
  }
  
  .why-us .icon-box .title a:hover {
    color: #68A4C4;
  }
  
  .why-us .icon-box .description {
    margin-left: 95px;
    line-height: 24px;
    font-size: 14px;
  }
  
  .why-us .video-box {
    position: relative;
  }
  
  .why-us .video-box img {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
  .why-us .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#68A4C4 50%, rgba(104, 164, 196, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  
  .why-us .play-btn::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-40%) translateY(-50%);
            transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .why-us .play-btn::before {
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate-btn 2s;
    animation: pulsate-btn 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(104, 164, 196, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  
  .why-us .play-btn:hover::after {
    border-left: 15px solid #68A4C4;
    -webkit-transform: scale(20);
            transform: scale(20);
  }
  
  .why-us .play-btn:hover::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-40%) translateY(-50%);
            transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    -webkit-animation: none;
    animation: none;
    border-radius: 0;
  }
  
  
  
  
  @-webkit-keyframes pulsate-btn {
    0% {
      -webkit-transform: scale(0.6, 0.6);
              transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  @keyframes pulsate-btn {
    0% {
      -webkit-transform: scale(0.6, 0.6);
              transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
      opacity: 0;
    }
  }
      


/* Googlefont Poppins CDN Link */

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
/*-----Sidebar---*/
#dsidebar{
  position: fixed;
  height: 100%;
  width: 280px;
  background: #0A2558;
  transition: all 0.5s ease;
  z-index: 999;
}
#dsidebar.close{
  width: 78px;
}
#dsidebar .logo-details{
  height: 80px;
  display: flex;
  align-items: center;
}
#dsidebar .logo-details i{
  font-size: 28px;
  font-weight: 500;
  color: #fff;
  min-width: 60px;
  text-align: center
}
#dsidebar .logo-details .logo_img{
    width: 30px;
    height: 30px;
    min-width: 30px;
    margin-left: 20px;
    margin-right: 10px;
}
#dsidebar .logo-details .logo_name{
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}
#dsidebar.close .logo-details .logo_name{
display: none;
}

#dsidebar .nav-links{
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto;
}
#dsidebar.close .nav-links{
  overflow: visible;
}
#dsidebar .nav-links::-webkit-scrollbar{
  display: none;
}
#dsidebar .nav-links li{
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}
#dsidebar .nav-links li:hover{
  background: #1d1b31;
}

#dsidebar.close .nav-links li .iocn-link{
  display: block
}
#dsidebar .nav-links li i{
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}
#dsidebar .nav-links li i.arrow{
 float: right;
 margin-right: 5px;
}
#dsidebar .nav-links li.showMenu i.arrow{
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
#dsidebar.close .nav-links i.arrow{
  display: none;
}
#dsidebar .nav-links li a{
  display: flex;
  align-items: center;
  text-decoration: none;
}
#dsidebar .nav-links li a .link_name{
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  transition: all 0.4s ease;
}
#dsidebar.close .nav-links li a .link_name{
  opacity: 0;
  pointer-events: none;
}
#dsidebar .nav-links li .sub-menu{
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #1d1b31;
  display: none;
}
#dsidebar .nav-links li.showMenu .sub-menu{
  display: block;
}
#dsidebar .nav-links li .sub-menu a{
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
#dsidebar .nav-links li .sub-menu a:hover{
  opacity: 1;
}
#dsidebar.close .nav-links li .sub-menu{
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
#dsidebar.close .nav-links li:hover .sub-menu{
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
#dsidebar .nav-links li .sub-menu .link_name{
  display: none;
}
#dsidebar.close .nav-links li .sub-menu .link_name{
  font-size: 18px;
  opacity: 1;
  display: block;
}
#dsidebar .nav-links li .sub-menu.blank{
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
#dsidebar .nav-links li:hover .sub-menu.blank{
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
#dsidebar .nav-links .log_out{
  position: absolute;
  bottom: 0;
  width: 100%;
}

#dsidebar .nav-links li .iocn-link{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#dsidebar.close .nav-links li .iocn-link{
  display: block
}


/*-------Profile Detail-------*/
#dsidebar .profile-details{
  position: fixed;
  bottom: 0;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1b31;
  padding: 12px 0;
  transition: all 0.5s ease;
}
#dsidebar.close .profile-details{
  background: none;
}
#dsidebar.close .profile-details{
  width: 78px;
}
#dsidebar .profile-details .profile-content{
  display: flex;
  align-items: center;
}
#dsidebar .profile-details img{
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #1d1b31;
  transition: all 0.5s ease;
}
#dsidebar.close .profile-details img{
  padding: 10px;
}

#dsidebar .profile-details .profile_name,
#dsidebar .profile-details .job{
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}
#dsidebar.close .profile-details i,
#dsidebar.close .profile-details .profile_name,
#dsidebar.close .profile-details .job{
  display: none;
}
#dsidebar .profile-details .job{
  font-size: 12px;
}


/*-------Header-------*/
.home-section{
  position: relative;
  padding: 0;
  background: #f5f5f5;
  /*min-height: 100vh;*/
  width: calc(100% - 280px);
  left: 280px;
  transition: all 0.5s ease;
}
#dsidebar.close ~ .home-section{
  width: calc(100% - 78px);
  left: 78px;
}
.home-section nav{
  display: flex;
  justify-content: space-between;
  height: 80px;
  background: #fff;
  display: flex;
  align-items: center;
  position: fixed;
  width: calc(100% - 280px);
  left: 280px;
  z-index: 100;
  padding: 0 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
}
#dsidebar.close ~ .home-section nav{
  left: 78px;
  width: calc(100% - 78px);
}
.home-section nav .sidebar-button{
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
}
nav .sidebar-button i{
  font-size: 35px;
  margin-right: 10px;
  cursor: pointer;
}
.home-section nav .search-box{
  position: relative;
  height: 50px;
  max-width: 550px;
  width: 100%;
  margin: 0 20px;
}
/* nav .search-box input{
  height: 100%;
  width: 100%;
  outline: none;
  background: #F5F6FA;
  border: 2px solid #EFEEF1;
  border-radius: 6px;
  font-size: 18px;
  padding: 0 15px;
}
nav .search-box .bx-search{
  position: absolute;
  height: 40px;
  width: 40px;
  background: #2697FF;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 22px;
  transition: all 0.4 ease;
} */
.home-section nav .profile-details{
  display: flex;
  align-items: center;
  background: #F5F6FA;
  border: 2px solid #EFEEF1;
  border-radius: 6px;
  height: 50px;
  min-width: 60px;
  padding: 10px;
  cursor: pointer;
}
nav .profile-details img{
  height: 40px;
  width: 40px;
  border-radius: 6px;
  object-fit: cover;
}
nav .profile-details .admin_name{
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin: 0 10px;
  white-space: nowrap;
}
nav .profile-details i{
  font-size: 25px;
  color: #333;
}
.home-section .home-content{
  position: relative;
  padding-top: 104px;
}
.home-content .overview-boxes{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
 /* padding: 0 20px;*/
 padding: 20px;
  margin-bottom: 26px;
}

.overview-boxes .box{
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 4 - 15px);
  background: #fff;
  padding: 15px 14px;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);
}
.overview-boxes .box-topic{
  font-size: 20px;
  font-weight: 500;
}
.home-content .box .number{
  display: inline-block;
  font-size: 35px;
  margin-top: -6px;
  font-weight: 500;
}
.home-content .box .indicator{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.home-content .box .indicator i{
  height: 20px;
  width: 20px;
  background: #b08fda;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  margin-right: 5px;
}
.box .indicator i.down{
  background: #e87d88;
}
.home-content .box .indicator .text{
  font-size: 12px;
}
.home-content .box .award{
  display: inline-block;
  font-size: 32px;
  height: 50px;
  width: 50px;
  background: #cce5ff;
  line-height: 50px;
  text-align: center;
  color: #66b0ff;
  border-radius: 12px;
  margin: -5px 0 0 6px;
}
.home-content .box .cart.two{
   color: #2BD47D;
   background: #C0F2D8;
 }
.home-content .box .cart.three{
   color: #ffc233;
   background: #ffe8b3;
 }
.home-content .box .cart.four{
   color: #e05260;
   background: #f7d4d7;
 }
.home-content .total-order{
  font-size: 20px;
  font-weight: 500;
}
.home-content .sales-boxes{
  display: flex;
  justify-content: space-between;
  /* padding: 0 20px; */
}

/* left box */
.home-content .sales-boxes .recent-sales{
  width: 100%;
  background: #fff;
  padding: 20px 30px;
  margin: 0 20px;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.home-content .sales-boxes .sales-details{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sales-boxes .box .title{
  font-size: 24px;
  font-weight: 500;
  /* margin-bottom: 10px; */
}
.sales-boxes .sales-details li.topic{
  font-size: 20px;
  font-weight: 500;
}
.sales-boxes .sales-details li{
  list-style: none;
  margin: 8px 0;
}
.sales-boxes .sales-details li a{
  font-size: 18px;
  color: #333;
  font-size: 400;
  text-decoration: none;
}
.sales-boxes .box .button{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.sales-boxes .box .button a{
  color: #fff;
  background: #0A2558;
  padding: 4px 12px;
  font-size: 15px;
  font-weight: 400;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.3s ease;
}
.sales-boxes .box .button a:hover{
  background:  #0d3073;
}

/* Right box */
.home-content .sales-boxes .top-sales{
  width: 35%;
  background: #fff;
  padding: 20px 30px;
  margin: 0 20px 0 0;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.sales-boxes .top-sales li{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.sales-boxes .top-sales li a img{
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 12px;
  margin-right: 10px;
  background: #333;
}
.sales-boxes .top-sales li a{
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sales-boxes .top-sales li .product,
.price{
  font-size: 17px;
  font-weight: 400;
  color: #333;
}
/*Tab Area*/
/*----Tab Content----*/
.tab-wrapper nav{
  justify-content: start;
  padding-left:20px;
  height: 40px;
  /*margin-left: 30px;
  max-width: 79%;*/
  }
  
  .tab-item-wrapper{
      padding-top: 80px;
  }


/* Responsive Media Query */
/*
@media (max-width: 1240px) {
  #dsidebar{
    width: 60px;
  }
  #dsidebar.close{
    width: 220px;
  }
  .home-section{
    width: calc(100% - 60px);
    left: 60px;
  }
  #dsidebar.close ~ .home-section{
    /* width: calc(100% - 220px); 
    overflow: hidden;
    left: 220px;
  }
  .home-section nav{
    width: calc(100% - 60px);
    left: 60px;
  }
  #dsidebar.close ~ .home-section nav{
    width: calc(100% - 220px);
    left: 220px;
  }
}

@media (max-width: 1150px) {
  .home-content .sales-boxes{
    flex-direction: column;
  }
  .home-content .sales-boxes .box{
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 30px;
  }
  .home-content .sales-boxes .top-sales{
    margin: 0;
  }
}*/
@media (max-width: 1000px) {
  .overview-boxes .box{
    width: calc(100% / 2 - 15px);
    margin-bottom: 15px;
  }
}
@media (max-width: 700px) {
  nav .sidebar-button .dashboard,
  nav .profile-details .admin_name,
  nav .profile-details i{
    display: none;
  }
  .home-section nav .profile-details{
    height: 50px;
    min-width: 40px;
  }
  .home-content .sales-boxes .sales-details{
    width: 560px;
  }
}
@media (max-width: 550px) {
  .overview-boxes .box{
    width: 100%;
    margin-bottom: 15px;
  }
  #dsidebar.close ~ .home-section nav .profile-details{
    display: none;
  }
  #dsidebar.close .nav-links li .sub-menu{
    display: none;
  }
}

@media (max-width:390px) {
  #dsidebar { 
    width: 60px;
}
#dsidebar .logo-details {
  height: 80px;
  display: flex;
  align-items: center;
}
#dsidebar .logo-details .logo_img {
  width: 30px;
  height: 30px;
  min-width: 30px;
  margin-left: 20px;
  margin-right: 10px;
}
#dsidebar.close .logo-details .logo_name {
 display: none;
}
#dsidebar .logo-details .logo_name {
  display: none;
}
#dsidebar .profile-details {
  position: fixed;
  bottom: 0;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1b31;
  padding: 12px 0;
  transition: all 0.5s ease;
}
#dsidebar .profile-details img {
  height: 30px;
  width: 30px;
  min-width: 30px;
  border-radius: 8px;
  margin: 0 10px 0 20px;
}
#dsidebar .profile-details .job {
 display: none ;
}
#dsidebar .profile-details i{
  display: none ;
 }

}

.card .special-issue-image{
    height: 350px;
  }
.card.title-text{
   
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    margin-left: 20px;
}
.card.footer-text{
    padding: 0.5rem 1rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.divider-style{
    border: 5px solid;
}

.rdw-editor-main {
     height: 100%;
    overflow: auto;
    box-sizing: border-box;
}
.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;

  }
  .editor-class {
    background-color:lightgray;
    padding: 1rem;
    border: 1px solid #ccc;
    
  }
  .toolbar-class {
    border: 1px solid #ccc;
  }


.rdw-editor-main {
     height: 100%;
    overflow: auto;
    box-sizing: border-box;
}
.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;

  }
  .editor-class {
    background-color:lightgray;
    padding: 1rem;
    border: 1px solid #ccc;
    
  }
  .toolbar-class {
    border: 1px solid #ccc;
  }
.overview-boxes .box{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    background: #fff;
    padding: 15px 14px;
    border-radius: 12px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  }

.rdw-editor-main {
     height: 100%;
    overflow: auto;
    box-sizing: border-box;
}
.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;

  }
  .editor-class {
    background-color:lightgray;
    padding: 1rem;
    border: 1px solid #ccc;
    
  }
  .toolbar-class {
    border: 1px solid #ccc;
  }

.rdw-editor-main {
    height: 100%;
   overflow: auto;
   box-sizing: border-box;
}
.wrapper-class {
   padding: 1rem;
   border: 1px solid #ccc;

 }
 .editor-class {
   background-color:lightgray;
   padding: 1rem;
   border: 1px solid #ccc;
   
 }
 .toolbar-class {
   border: 1px solid #ccc;
 }
.overview-boxes .box{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    background: #fff;
    padding: 15px 14px;
    border-radius: 12px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  }

.rdw-editor-main {
     height: 100%;
    overflow: auto;
    box-sizing: border-box;
}
.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
  }
.editor-class {
  background-color:lightgray;
  padding: 1rem;
  border: 1px solid #ccc; 
}
.toolbar-class {
  border: 1px solid #ccc;
}
.quill {
  width: auto;
}
.ql-container.ql-snow {
    border: 2px solid #ccc;
    height: 400px;
    overflow: scroll;
  }
.overview-boxes .box{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    background: #fff;
    padding: 15px 14px;
    border-radius: 12px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  }

.rdw-editor-main {
     height: 100%;
    overflow: auto;
    box-sizing: border-box;
}
.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
  }
.editor-class {
  background-color:lightgray;
  padding: 1rem;
  border: 1px solid #ccc; 
}
.toolbar-class {
  border: 1px solid #ccc;
}
.quill {
  width: auto;
}
.ql-container.ql-snow {
    border: 2px solid #ccc;
    height: 400px;
    overflow: scroll;
  }
.overview-boxes .box{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    background: #fff;
    padding: 15px 14px;
    border-radius: 12px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  }

.rdw-editor-main {
     height: 100%;
    overflow: auto;
    box-sizing: border-box;
}
.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
  }
.editor-class {
  background-color:lightgray;
  padding: 1rem;
  border: 1px solid #ccc; 
}
.toolbar-class {
  border: 1px solid #ccc;
}
.quill {
  width: auto;
}
.ql-container.ql-snow {
    border: 2px solid #ccc;
    height: 400px;
    overflow: scroll;
  }
.overview-boxes .box{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    background: #fff;
    padding: 15px 14px;
    border-radius: 12px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  }

.rdw-editor-main {
     height: 100%;
    overflow: auto;
    box-sizing: border-box;
}
.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
  }
.editor-class {
  background-color:lightgray;
  padding: 1rem;
  border: 1px solid #ccc; 
}
.toolbar-class {
  border: 1px solid #ccc;
}
.quill {
  width: auto;
}
.ql-container.ql-snow {
    border: 2px solid #ccc;
    height: 400px;
    overflow: scroll;
  }
.overview-boxes .box{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    background: #fff;
    padding: 15px 14px;
    border-radius: 12px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  }

.rdw-editor-main {
     height: 100%;
    overflow: auto;
    box-sizing: border-box;
}
.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
  }
.editor-class {
  background-color:lightgray;
  padding: 1rem;
  border: 1px solid #ccc; 
}
.toolbar-class {
  border: 1px solid #ccc;
}
.quill {
  width: auto;
}
.ql-container.ql-snow {
    border: 2px solid #ccc;
    height: 400px;
    overflow: scroll;
  }
.overview-boxes .box{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    background: #fff;
    padding: 15px 14px;
    border-radius: 12px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  }

.rdw-editor-main {
     height: 100%;
    overflow: auto;
    box-sizing: border-box;
}
.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
  }
.editor-class {
  background-color:lightgray;
  padding: 1rem;
  border: 1px solid #ccc; 
}
.toolbar-class {
  border: 1px solid #ccc;
}
.quill {
  width: auto;
}
.ql-container.ql-snow {
    border: 2px solid #ccc;
    height: 400px;
    overflow: scroll;
  }
