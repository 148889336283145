/*--------------------------------------------------------------
  # Hero No Slider Section
  --------------------------------------------------------------*/
  #main {
    margin-top: 0px;
  }
  #hero-no-slider {
    width: 100%;
    /* height: 40vh;  */
    max-height: 600px;
    overflow: hidden;
    position: relative;
    text-align: center;
   background:  url("./assets/images/hero-bgimg.jpg") center top no-repeat;
   /*background: #0a1f25;*/
  }
  
  #hero-no-slider::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./assets/images/hero-bgimg.jpg") center top no-repeat;
    /* background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(153, 123, 123, 0.5)), url("./assets/images/hero-bgimg.jpg") center top no-repeat; */
    /*background: #0a1f25; */
   
  }
  
  #hero-no-slider h2 {
    color: #fff;
    margin-top: 120px;
    margin-bottom: 12px;
    font-size: 48px;
    font-weight: 700;
    font-family: sans-serif;
  }
  
  #hero-no-slider h1 {
    color: #fff;
    margin-top: 120px;
    margin-bottom: 12px;
    font-size: 48px;
    font-weight: 700;
    font-family: sans-serif;
  }

  #hero-no-slider p {
    color: #fff;
  }
  
  #hero-no-slider .btn-get-started {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    background: #94c045;
    /* border: 2px solid #94c045; */
  }
  
  /* #hero-no-slider .btn-get-started:hover {
    background: #94c045;
    color: #fff;
    text-decoration: none;
  } */

  #hero-no-slider .btn-get-started:hover {
    /* background: #94c045; */
    color: #01081a;
    text-decoration: none;
  }
  
  @media (max-width: 575px) {
    #hero-no-slider h2 {
      font-size: 30px;
    }
  }
  
  @media screen and (min-width: 1400px) {

    #main {
    margin: 0 auto;
    width: 1400px;
    
    }
    #header .container-fluid {
      margin: 0 auto;
      width: 1400px;
      background: #fff;
      padding: 10px 0px 10px 0;
    }
    #hero-no-slider {
      margin: 0 auto;
      width: 1400px;
    }
    .breadcrumbs{
      margin: 0 auto;
      width: 1400px;
    }
  }
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
    font-family: "IBM Plex Sans", sans-serif;
    color: #444;
    background-color: #f4f8fa;
  }
  
  a {
    color: #4fa6d5;
    text-decoration: none;
  }
  
  a:hover {
    color: #45beff;
    text-decoration: none;
  }
  
  h1, h2, h3, h4, h5, h6, .font-primary {
    font-family: "Roboto", sans-serif;
  }
  
 
  /*--------------------------------------------------------------
  # SearchBar
  --------------------------------------------------------------*/
  @media (max-width: 991px) {
    .search-box{
    /* transform:translateX(-30px) */
    transform:translateX(-30px) !important
    }
  }
  .mobile-search-toggle {
    color: rgba(30, 67, 86);
    font-size: 25px;
    padding: 10px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
  }
  .search-box{
    /* background: #1e4356; */
    background:#21494f;
    position: absolute;
    top:54px;
    right: 0.1%;
    width: 350px;
    height: 60px;
    box-shadow: 0 0 10px rgba(0,0,0, 0.5);
    border-top: 2px solid #f2ba49;
    display: none;
    z-index: 9999;
  }
  .search-box::before{
    content: "";
    position: absolute;
    top:-32px;
    right: 5px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid transparent;
    border-bottom: 14px solid #f2ba49;
  }
  .search-box input[type="text"]{
    width: 230px;
    height: 40px;
    padding: 5px 10px;
    margin-left: 23px;
    border-top: 2px solid #f2ba49;
    outline: none;
  }
  .search-box input[type="button"]{
    width: 70px;
    height: 40px;
    padding: 8px 0;
    background: #94c146;
    border: 1px solid #94c146;
    outline: none;
    cursor: pointer;
    color: #fff;
    margin-left: -5px;
    margin-top: 10px;
  }
  
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 25px;
    bottom: 15px;
    z-index: 99999;
    background: #94c045;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #392933;
    transition: all 0.4s;
  }
  
  .back-to-top i {
    font-size: 24px;
    color: #392933;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: #94c045;
    border-color: #ffffff;
    color: #fff;
  }
  .back-to-top i:hover {
    color: #fff;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 30px 10px;
    background-color: #fff;
  }
  
  .section-bg {
    background-color: #f3f8fa;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .section-title h2 {
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
  }
  
  .section-title h2::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }
  
  .section-title h2::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #68A4C4;
    bottom: 0;
    left: calc(50% - 20px);
  }
  
  .section-title p {
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  .breadcrumbs {
    padding: 15px 0;
    background-color: #ffff;
    min-height: 40px;
    border-bottom: 2px solid #f3f8fa;
  }
  
  .breadcrumbs h2 {
    font-size: 28px;
    font-weight: 300;
  }
  
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .breadcrumbs ol li + li {
    padding-left: 10px;
  }
  
  .breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #6c757d;
    content: "/";
  }
  
  @media (max-width: 768px) {
    .breadcrumbs .d-flex {
      display: block !important;
      font-size: 75%;
    }
    .breadcrumbs ol {
      display: block;
    }
    .breadcrumbs ol li {
      display: inline-block;
    }
  }
  
  
  /*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about{
  /* margin-top:10px; */
   margin-top:0px; /*!important */
}
.about h3 {
  font-weight: 400;
  font-size: 26px;
}

.about ul {
  list-style: none;
  padding: 0;
}

.about ul li {
  padding-bottom: 10px;
}

.about ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #68A4C4;
}

.about p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .container {
 /* box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);*/
  padding-bottom: 15px;
  background: #fff;
 /* transform: translateY(-10%);*/
}

.about .count-box {
  width: 100%;
}

.about .count-box i {
  display: block;
  font-size: 48px;
  color: #94c045;
  float: left;
  line-height: 0;
}

.about .count-box span {
  font-size: 28px;
  line-height: 24px;
  display: block;
  font-weight: 700;
  color: #646c55;
  margin-left: 60px;
}

.about .count-box p {
  padding: 5px 0 0 0;
  margin: 0 0 0 60px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #646c55;
}

.about .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #646c55;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  transition: ease-in-out 0.3s;
}

.about .count-box a:hover {
  color: #8b9578;
}

.about .content {
  font-size: 15px;
}

.about .content h3 {
  font-weight: 700;
  font-size: 24px;
  color: #3c4133;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
  padding-left: 28px;
  position: relative;
}

.about .content ul i {
  font-size: 24px;
  color: #94c045;
  position: absolute;
  left: 0;
  top: -2px;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(#94c045 50%, rgba(148, 192, 69, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.about .play-btn::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.about .play-btn::before {
  content: '';
  position: absolute;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 2s;
  animation: pulsate-btn 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(148, 192, 69, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.about .play-btn:hover::after {
  border-left: 15px solid #94c045;
  transform: scale(20);
}

.about .play-btn:hover::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}

@-webkit-keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
@media (min-width: 768px){
#main{
  margin-top: 0px;
}
}
@media (min-width: 576px){
#main{
    margin-top: 0px;
  }
}
/*--------------------------------------------------------------
# Our Skills
--------------------------------------------------------------*/
.skills {
    padding: 60px 0;
    margin-top: 90px;
  }
  
  .skills .progress {
    height: 35px;
    margin-bottom: 10px;
  }
  
  .skills .progress .skill {
    line-height: 35px;
    padding: 0;
    margin: 0 0 0 20px;
    text-transform: uppercase;
  }
  
  .skills .progress .skill .val {
    float: right;
    font-style: normal;
    margin: 0 20px 0 0;
  }
  
  .skills .progress-bar {
    width: 1px;
    text-align: left;
    transition: .9s;
  }

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us .container {
    box-shadow: 0 5px 25px 0 rgba(214, 215, 216, 0.6);
    background: #fff;
  }
  
  .why-us .icon-box + .icon-box {
    margin-top: 50px;
  }
  
  .why-us .icon-box .icon {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    background: #f3f8fa;
    border-radius: 6px;
    transition: 0.5s;
  }
  
  .why-us .icon-box .icon i {
    color: #68A4C4;
    font-size: 32px;
  }
  
  .why-us .icon-box:hover .icon {
    background: #68A4C4;
  }
  
  .why-us .icon-box:hover .icon i {
    color: #fff;
  }
  
  .why-us .icon-box .title {
    margin-left: 95px;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 18px;
    text-transform: uppercase;
  }
  
  .why-us .icon-box .title a {
    color: #343a40;
    transition: 0.3s;
  }
  
  .why-us .icon-box .title a:hover {
    color: #68A4C4;
  }
  
  .why-us .icon-box .description {
    margin-left: 95px;
    line-height: 24px;
    font-size: 14px;
  }
  
  .why-us .video-box {
    position: relative;
  }
  
  .why-us .video-box img {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
  .why-us .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#68A4C4 50%, rgba(104, 164, 196, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  
  .why-us .play-btn::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .why-us .play-btn::before {
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate-btn 2s;
    animation: pulsate-btn 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(104, 164, 196, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  
  .why-us .play-btn:hover::after {
    border-left: 15px solid #68A4C4;
    transform: scale(20);
  }
  
  .why-us .play-btn:hover::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    -webkit-animation: none;
    animation: none;
    border-radius: 0;
  }
  
  
  
  
  @-webkit-keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
      
