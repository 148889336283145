
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    height: 100px;
    transition: all 0.5s;
    z-index: 9999 ;
    transition: all 0.5s;
    background: #f4f8fa;
    /*important */
    /* overflow: hidden; 
    position: relative; */
  }
  
  #header.header-transparent {
    /* background:  #fff; */
    background: #f4f8fa;
  }
  
  #header.header-scrolled {
    background: rgba(30, 67, 86, 0.8);
    height: 60px;
  }
  
  #header .logo h1 {
    font-size: 28px;
    margin: 0;
    padding: 4px 0;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
  
  #header .logo h1 a, #header .logo h1 a:hover {
    color: #1e4356;
    text-decoration: none;
  }
  
  #header .logo img {
    /* padding: 0; */
    padding: 5px !important;
    margin: 0;
    max-height: 80px;
  }


    @media all and (max-width: 640px) {
      #header .container-fluid {
         background: #fff;
         padding: 10px 0 10px 0;
      }
    }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }
  
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .navbar li {
    position: relative;
  }
  
  .navbar a, .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-family: "IBM Plex Sans",sans-serif ;
    font-weight: 525;
    font-size: 16px;
    color: #000;
    text-decoration: none;
    white-space: nowrap;
    transition: 0.3s;
  }
  
  .navbar a i, .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  
  /* .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
    color: #94c045;
  } */

   .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a ::after {
   
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 4px;
    transform-origin: scaleX(0);
    transform-origin: bottom right;
    transition: all 0.5s ease;
    color: #94c045 ;
   }

   .navbar  .nav-item:hover > a ::after {
    width: 100%;
  }
  
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
  }
  
  .navbar .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 14px;
    text-transform: none;
    color: #1c3745;
  }
  
  .navbar .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
    color: #68A4C4;
  }
  
  .navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  
  .navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  .searchbar{
    position:relative;
    right:5px;
    padding:10px;
  }
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
    .navbar .dropdown .dropdown:hover > ul {
      left: -100%;
    }
  }
  
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: rgba(30, 67, 86);
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
    padding: 8px !important;

  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
    .navbar ul {
      display: none;
    }
  }
  
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 100px;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(17, 38, 48, 0.9);
    transition: 0.3s;
    z-index: 9999;
  }
  
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 55px;
    right: 15px;
  }
  
  .navbar-mobile ul {
    display: block;
    position: relative;
    top: 100px;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
    transform: translate(0px, -170px);
  }
 
  .navbar-mobile a {
    padding: 10px 20px;
    font-size: 15px;
    color: #1e4356;
  }
  .navbar-mobile li{
    display: flex;
    justify-content: center;
  }
  .navbar-mobile li:hover{
    background-color: #031e27;
  }
  .navbar-mobile a:hover, .navbar-mobile .active > a {
    color: #94c045;
  }
  
  .navbar-mobile .getstarted {
    margin: 15px;
  }
  
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  
  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
    color: #68A4C4;
  }
  
  .navbar-mobile .dropdown > .dropdown-active {
    display: block;
  }
  @media (min-width: 768px){
    .navbar-mobile ul {
      top:80px;
      transform: translate(0px, -170px);
      }
    }
   
    /*@media (min-width: 576px){
    #main{
        margin-top: 160px;
      }
    }*/
  /*--------------------------------------------------------------
# SearchBar
--------------------------------------------------------------*/
@media (max-width: 991px) {
    .search-box{
    transform:translateX(-30px)
    }
  }
  .mobile-search-toggle {
    color: rgba(30, 67, 86);
    font-size: 15px;
    padding: 10px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
  }
  .search-box{
    background: #1e4356;
    position: absolute;
    top:54px;
    right: 0.1%;
    width: 350px;
    height: 60px;
    box-shadow: 0 0 10px rgba(0,0,0, 0.5);
    border-top: 2px solid#f2ba49;
    display: none;
    z-index: 9999;
  }
  .search-box::before{
    content: "";
    position: absolute;
    top:-32px;
    right: 5px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid transparent;
    border-bottom: 14px solid #f2ba49;
  }
  .search-box input[type="text"]{
    width: 230px;
    height: 40px;
    padding: 5px 10px;
    margin-left: 23px;
    border-top: 1px solid #f2ba49;
    outline: none;
  }
  .search-box input[type="button"]{
    width: 70px;
    height: 40px;
    padding: 5px 0;
    background: #f2ba49;
    border: 1px solid #f2ba49;
    outline: none;
    cursor: pointer;
    color: #fff;
    margin-left: -5px;
    margin-top: 10px;
  }