.refertag a {
    color: #4d8a17;
}

.refertag a:hover {
    color:#6ba439;
}

.refertag p {
    line-height: normal;
    margin-bottom: 0.2rem;
}

.refertag p a {
    line-height: normal;
    margin-bottom: 0.1rem;
}