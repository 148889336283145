
#hero-no-slider {
  width: 100%;
  /* height: 40vh; */

  /* max-height: 400px; */
  overflow: hidden;
  position: relative;
  text-align: center;
 background:  url("../assets/images/hero-bgimg.jpg") center top no-repeat;
 /*background: #0a1f25;*/
}

#hero-no-slider::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(153, 123, 123, 0.5)), url("../assets/images/hero-bgimg.jpg") center top no-repeat;
  /*background: #0a1f25; */
 
}

#hero-no-slider h2 {
  color: #fff;
  margin-top: 120px;
  margin-bottom: 12px;
  font-size: 48px;
  font-weight: 700;
}

#hero-no-slider p {
  color: #fff;
}

#hero-no-slider .btn-get-started {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  background: #94c045;
  /* border: 2px solid #94c045; */
}

/* #hero-no-slider .btn-get-started:hover {
  background: #94c045;
  color: #fff;
  text-decoration: none;
} */

#hero-no-slider .btn-get-started:hover {
  /* background: #94c045; */
  color: #01081a;
  text-decoration: none;
}

@media (max-width: 575px) {
  #hero-no-slider h2 {
    font-size: 30px;
  }
}

/*--------------------------------------------------------------
# Our Services
--------------------------------------------------------------*/
.services {
    padding-bottom: 20px;
  }
  
  .services .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    margin: 0  0 40px 0;
    background: #fff;
    color: #111;
    box-shadow: 0 5px 26px 0 rgba(68, 88, 144, 0.14);
    transition: all 0.3s ease-in-out;
    text-align: center;
    border: 1px solid #fff;
  }
  
  .services .icon {
    margin: 0 auto 20px auto;
    padding-top: 17px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    width: 72px;
    height: 72px;
  }
  
  .services .icon i {
    font-size: 36px;
    line-height: 1;
  }
  
  .services .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .services .title a {
    color: #111;
  }
  
  .services .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
  }
  
  .services .icon-box-pink .icon {
    background: #fceef3;
  }
  
  .services .icon-box-pink .icon i {
    color: #ff689b;
  }
  
  .services .icon-box-pink:hover {
    border-color: #ff689b;
  }
  
  .services .icon-box-cyan .icon {
    background: #e6fdfc;
  }
  
  .services .icon-box-cyan .icon i {
    color: #3fcdc7;
  }
  
  .services .icon-box-cyan:hover {
    border-color: #3fcdc7;
  }
  
  .services .icon-box-green .icon {
    background: #eafde7;
  }
  
  .services .icon-box-green .icon i {
    color: #41cf2e;
  }
  
  .services .icon-box-green:hover {
    border-color: #41cf2e;
  }
  
  .services .icon-box-blue .icon {
    background: #e1eeff;
  }
  
  .services .icon-box-blue .icon i {
    color: #2282ff;
  }
  
  .services .icon-box-blue:hover {
    border-color: #2282ff;
  }

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us .container {
    box-shadow: 0 5px 25px 0 rgba(214, 215, 216, 0.6);
    background: #fff;
  }
  
  .why-us .icon-box + .icon-box {
    margin-top: 50px;
  }
  
  .why-us .icon-box .icon {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    background: #f3f8fa;
    border-radius: 6px;
    transition: 0.5s;
  }
  
  .why-us .icon-box .icon i {
    color: #68A4C4;
    font-size: 32px;
  }
  
  .why-us .icon-box:hover .icon {
    background: #68A4C4;
  }
  
  .why-us .icon-box:hover .icon i {
    color: #fff;
  }
  .why-us .card .special-issue-image{
    height: 350px;
  }
  .why-us .card .proposal-issue-image{
    height: 250px;
  }
  .why-us .card .Event-proposal-image{
    height: 150px;
  }
  .why-us .icon-box .title {
    margin-left: 95px;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 18px;
    text-transform: uppercase;
  }
  
  .why-us .icon-box .title a {
    color: #343a40;
    transition: 0.3s;
  }
  
  .why-us .icon-box .title a:hover {
    color: #68A4C4;
  }
  
  .why-us .icon-box .description {
    margin-left: 95px;
    line-height: 24px;
    font-size: 14px;
  }
  
  .why-us .video-box {
    position: relative;
  }
  
  .why-us .video-box img {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
  .why-us .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#68A4C4 50%, rgba(104, 164, 196, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  
  .why-us .play-btn::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .why-us .play-btn::before {
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate-btn 2s;
    animation: pulsate-btn 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(104, 164, 196, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  
  .why-us .play-btn:hover::after {
    border-left: 15px solid #68A4C4;
    transform: scale(20);
  }
  
  .why-us .play-btn:hover::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    -webkit-animation: none;
    animation: none;
    border-radius: 0;
  }
  @-webkit-keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .row + .row {
    margin-top: 100px;
  }
  
  .features h3 {
    font-weight: 400;
    font-size: 24px;
  }
  
  .features ul {
    list-style: none;
    padding: 0;
  }
  
  .features ul li {
    padding-bottom: 10px;
  }
  
  .features ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #68A4C4;
  }
  
  .features p:last-child {
    margin-bottom: 0;
  }

