/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    background: #194350;
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;
  }
  
  #footer .footer-newsletter {
    padding: 50px 0;
    background: #0d2735;
  }
  
  #footer .footer-newsletter h4 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
    color: #a2cce3;
  }
  
  #footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
  }
  
  #footer .footer-newsletter form input[type="email"] {
    border: 0;
    padding: 4px;
    width: calc(100% - 100px);
  }
  
  #footer .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    margin: 3px;
    background: #68A4C4;
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
  }
  
  #footer .footer-newsletter form input[type="submit"]:hover {
    background: #468db3;
  }
  
  #footer .footer-top {
    background:#194350;
    border-top: 1px solid #17455e;
    border-bottom: 1px solid #ffffff;
    padding: 60px 0 30px 0;
  }
  
  #footer .footer-top .footer-info {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-info h3 {
    font-size: 18px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    color: #ffffff;
    font-weight: 600;
  }
  
  #footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Roboto", sans-serif;
    color: #fff;
  }
  
  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background:#94c045;
    /* border: 1px solid #ffffff; */
    border: 1px solid #94c045; 
    color: #ffffff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .footer-top .social-links a:hover {
    background: #94c045;
    /* border: 1px solid #ffffff;  */
    
    color: #194350;
    text-decoration: none;
  }
  
  #footer .footer-top h4 {
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #ffffff;
    font-size: 18px;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    color: #a2cce3;
  }
  
  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-contact p {
    line-height: 26px;
  }
  
  #footer .copyright {
    text-align: center;
    padding-top: 30px;
  }
  
  #footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff;
  }
  
  #footer .credits a {
    color: #a2cce3;
  }
/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 25px;
    bottom: 15px;
    z-index: 99999;
    background: #94c045;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #392933;
    transition: all 0.4s;
  }
  
  .back-to-top i {
    font-size: 24px;
    color: #392933;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: #94c045;
    border-color: #ffffff;
    color: #fff;
  }
  .back-to-top i:hover {
    color: #fff;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }  

  .scroll-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 25px;
    bottom: 15px;
    z-index: 99999;
    background: #94c045;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #392933;
    transition: all 0.4s;
  }

  @media screen and (min-width:1400px) {
    .styles_scroll-to-top__2A70v{
      margin: 0 auto;
      width: 1400px;
    }
  }