#hero-no-slide-dsair {
    width: 100%;
    /* height: 40vh; */

    /* max-height: 400px; */
    overflow: hidden;
    position: relative;
    text-align: center;
    background:  url("../../assets/images/DS_AIR_New_Img.jpg") center top no-repeat;
   /* background:  url("../assets/images/DS_New_Img.jpg") center top no-repeat; */
   /*background: #0a1f25;*/
  }
  
  #hero-no-slide-dsair::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background:  url("../../assets/images/DS_AIR_New_Img.jpg") center top no-repeat;
    /* background: url("../assets/images/DS_New_Img.jpg") center top no-repeat; */
    /*background: #0a1f25; */
   
  }
  
  #hero-no-slide-dsair h2 {
    color: #fff;
    margin-top: 120px;
    margin-bottom: 12px;
    font-size: 48px;
    font-weight: 700;
  }
  
  #hero-no-slide-dsair p {
    color: #fff;
  }
  
  #hero-no-slide-dsair .btn-get-started {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    background: #94c045;
    /* border: 2px solid #94c045; */
  }
  
  /* #hero-no-slider .btn-get-started:hover {
    background: #94c045;
    color: #fff;
    text-decoration: none;
  } */

  #hero-no-slide-dsair .btn-get-started:hover {
    /* background: #94c045; */
    color: #01081a;
    text-decoration: none;
  }
  
  @media (max-width: 575px) {
    #hero-no-slide-dsair h2 {
      font-size: 30px;
    }
  }
  
  @media screen and (min-width: 1400px) {
    #hero-no-slide-dsair {
      margin: 0 auto;
      width: 1400px;
    }
  }

.blog #journalcard{
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    margin: 0 0 0 0;
    box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
}
.blog .card #journalpfimg_p {
height: 100%;
width: 100%;
object-fit: contain;
overflow: hidden;

}
.blog .card-text{
    margin-top: 20px;
}
@media (max-width: 991px){
    .blog #journalcard{
        padding: 10px 10px 10px 10px;
        box-sizing: border-box;
        margin: 0 0 0 0;
        box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
    }
    .blog .card #journalpfimg {
      
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    
    .blog .card .card-body .card-text {
        margin: 20px;
        font-size: 75%;
    }
}
