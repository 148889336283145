.card .special-issue-image{
    height: 350px;
  }
.card.title-text{
   
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    margin-left: 20px;
}
.card.footer-text{
    padding: 0.5rem 1rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.divider-style{
    border: 5px solid;
}