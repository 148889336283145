/*--------------------------------------------------------------
# Hero No Slider Section
--------------------------------------------------------------*/
#hero-no-slider {
    width: 100%;
    height: 75vh;
    overflow: hidden;
    position: relative;
    text-align: center;
  }
  
  #hero-no-slider::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
   /* background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(153, 123, 123, 0.5)), url("../../public/assets/img/hero-bgimg.jpg") center top no-repeat;*/
   background-color: #0f1c20;
   
  }
  
  #hero-no-slider h2 {
    color: #fff;
    margin-bottom: 12px;
    font-size: 48px;
    font-weight: 700;
  }
  
  #hero-no-slider p {
    color: #fff;
  }
  
  #hero-no-slider .btn-get-started {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    border: 2px solid #94c045;
  }
  
  #hero-no-slider .btn-get-started:hover {
    background: #94c045;
    color: #fff;
    text-decoration: none;
  }
  
  @media (max-width: 575px) {
    #hero-no-slider h2 {
      font-size: 30px;
    }
  }

  /*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
    padding: 15px 0;
    background-color: #f3f8fa;
    min-height: 40px;
  }
  
  .breadcrumbs h2 {
    font-size: 28px;
    font-weight: 300;
  }
  
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .breadcrumbs ol li + li {
    padding-left: 10px;
  }
  
  .breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #6c757d;
    content: "/";
  }
  
  @media (max-width: 768px) {
    .breadcrumbs .d-flex {
      display: block !important;
    }
    .breadcrumbs ol {
      display: block;
    }
    .breadcrumbs ol li {
      display: inline-block;
    }
  }
  /*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
    padding: 40px 0 20px 0;
  }
  .blog hr {
    height: 1px;
    margin-left: 0px;
    margin-bottom:15px;
  }
  .blog .hr-warning{
    background-image: -webkit-linear-gradient(left, rgba(210,105,30,.8), rgba(210,105,30,.6), rgba(0,0,0,0));
  }
  .blog .hr-success{
    background-image: -webkit-linear-gradient(left, rgba(15,157,88,.8), rgba(15, 157, 88,.6), rgba(0,0,0,0));
  }
  .blog .hr-primary{
    background-image: -webkit-linear-gradient(left, rgba(66,133,244,.8), rgba(66, 133, 244,.6), rgba(0,0,0,0));
  }
  .blog .hr-danger{
    background-image: -webkit-linear-gradient(left, rgba(244,67,54,.8), rgba(244,67,54,.6), rgba(0,0,0,0));
  }
  .blog .entry {
    padding: 30px;
    margin-bottom: 60px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .entry .entry-img {
    max-height: 440px;
    margin: -30px -30px 20px -30px;
    overflow: hidden;
  }
  
  .blog .entry .entry-title {
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0 0 20px 0;
  }
  
  .blog .entry .entry-title a {
    color: #1e4356;
    transition: 0.3s;
  }
  
  .blog .entry .entry-title a:hover {
    color: #68A4C4;
  }
  
  .blog .entry .entry-meta {
    margin-bottom: 15px;
    color: #72afce;
  }
  
  .blog .entry .entry-meta ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  
  .blog .entry .entry-meta ul li + li {
    padding-left: 20px;
  }
  
  .blog .entry .entry-meta i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0;
  }
  
  .blog .entry .entry-meta a {
    color: #777777;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
  }
  
  .blog .entry .entry-content p {
    font-size: 12px;
    line-height: 24px;
    text-align: justify;
  }
  
  .blog .entry .entry-content .read-more {
    -moz-text-align-last: right;
    text-align-last: right;
  }
  
  .blog .entry .entry-content .read-more a {
    display: inline-block;
    background: #68A4C4;
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
  }
  
  .blog .entry .entry-content .read-more a:hover {
    background: #7aafcb;
  }
  
  
  .blog .apply-filter {
    -moz-text-align-last: center;
    text-align-last: center;
  }
  
  .blog .apply-filter a {
    display: inline-block;
    background: #68A4C4;
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
    text-decoration: none;
  }
  
  .blog .apply-filter a:hover {
    background: #7aafcb;
  }
  
  
  
  
  
  .blog .entry .entry-content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
  }
  
  .blog .entry .entry-content blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
  }
  
  .blog .entry .entry-content blockquote p {
    color: #444;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
  }
  
  .blog .entry .entry-content blockquote::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #1e4356;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .blog .entry .entry-footer {
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
  }
  
  .blog .entry .entry-footer i {
    color: #4c99c1;
    display: inline;
  }
  
  .blog .entry .entry-footer a {
    color: #255269;
    transition: 0.3s;
  }
  
  .blog .entry .entry-footer a:hover {
    color: #68A4C4;
  }
  
  .blog .entry .entry-footer .cats {
    list-style: none;
    display: inline;
    padding: 0 10px 0 0;
    font-size: 14px;
  }
  
  .blog .entry .entry-footer .cats li {
    display: inline-block;
  }
  
  .blog .entry .entry-footer .tags {
    list-style: none;
    display: inline;
    padding: 0 10px 0 0;
    font-size: 14px;
  }
  
  .blog .entry .entry-footer .tags li {
    display: inline-block;
  }
  
  .blog .entry .entry-footer .tags li + li::before {
    padding-right: 6px;
    color: #6c757d;
    content: ",";
  }
  
  .blog .entry .entry-footer .share {
    font-size: 16px;
  }
  
  .blog .entry .entry-footer .share i {
    padding-left: 5px;
  }
  
  .blog .entry-single {
    margin-bottom: 30px;
  }
  
  .blog .blog-author {
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .blog-author img {
    width: 120px;
    margin-right: 20px;
  }
  
  .blog .blog-author h4 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0px;
    padding: 0;
    color: #1e4356;
  }
  
  .blog .blog-author .social-links {
    margin: 0 10px 10px 0;
  }
  
  .blog .blog-author .social-links a {
    color: rgba(30, 67, 86, 0.5);
    margin-right: 5px;
  }
  
  .blog .blog-author p {
    font-style: italic;
    color: #b7b7b7;
  }
  
  .blog .blog-comments {
    margin-bottom: 30px;
  }
  
  .blog .blog-comments .comments-count {
    font-weight: bold;
  }
  
  .blog .blog-comments .comment {
    margin-top: 30px;
    position: relative;
  }
  
  .blog .blog-comments .comment .comment-img {
    margin-right: 14px;
  }
  
  .blog .blog-comments .comment .comment-img img {
    width: 60px;
  }
  
  .blog .blog-comments .comment h5 {
    font-size: 16px;
    margin-bottom: 2px;
  }
  
  .blog .blog-comments .comment h5 a {
    font-weight: bold;
    color: #444;
    transition: 0.3s;
  }
  
  .blog .blog-comments .comment h5 a:hover {
    color: #68A4C4;
  }
  
  .blog .blog-comments .comment h5 .reply {
    padding-left: 10px;
    color: #1e4356;
  }
  
  .blog .blog-comments .comment h5 .reply i {
    font-size: 20px;
  }
  
  .blog .blog-comments .comment time {
    display: block;
    font-size: 14px;
    color: #2b607c;
    margin-bottom: 5px;
  }
  
  .blog .blog-comments .comment.comment-reply {
    padding-left: 40px;
  }
  
  .blog .blog-comments .reply-form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .blog-comments .reply-form h4 {
    font-weight: bold;
    font-size: 22px;
  }
  
  .blog .blog-comments .reply-form p {
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form input {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form input:focus {
    box-shadow: none;
    border-color: #b1d0e1;
  }
  
  .blog .blog-comments .reply-form textarea {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form textarea:focus {
    box-shadow: none;
    border-color: #b1d0e1;
  }
  
  .blog .blog-comments .reply-form .form-group {
    margin-bottom: 25px;
  }
  
  .blog .blog-comments .reply-form .btn-primary {
    border-radius: 4px;
    padding: 10px 20px;
    border: 0;
    background-color: #1e4356;
  }
  
  .blog .blog-comments .reply-form .btn-primary:hover {
    background-color: #255269;
  }
  
  .blog .blog-pagination {
    color: #387ea2;
  }
  
  .blog .blog-pagination ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .blog .blog-pagination li {
    margin: 0 5px;
    transition: 0.3s;
  }
  
  .blog .blog-pagination li a {
    color: #1e4356;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .blog .blog-pagination li.active, .blog .blog-pagination li:hover {
    background: #68A4C4;
  }
  
  .blog .blog-pagination li.active a, .blog .blog-pagination li:hover a {
    color: #fff;
  }
  /*--------------------------------------------------------------
  # Blog
  --------------------------------------------------------------*/
  .blog .row:after{
     content: "."; 
     visibility: hidden; 
     display: block; 
     height: 0; 
     clear: both;
  
  }
  
  
  .blog .card{
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    max-width: 300px;
  }
  .blog .card img{
   /* overflow: hidden;
    height: 50px;
    padding: 2px;*/
    width: 100%;
      height: 5vw;
      object-fit: cover;
  }
  .blog .sidebar {
    
    padding: 30px 30px 30px 30px;
    box-sizing: border-box;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    top: 80px;
  
  }
  
  .blog .sidebar .sidebar-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 15px 0;
    color: #1e4356;
    position: relative;
  }
  .blog .abtlistgrp .card{
    padding: 0 0 0 0;
  }
  .blog .abtlistgrp .card {
   padding: 0px 0px 0px 0px;
   box-sizing: border-box;
   margin: 20px 0 0 0;
   border-radius: 0;
   box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
   max-width: 500px;
  }
  .blog .abtlistgrp .card .btn-submit {
   background-color: #011520;
   color: #ffffff;
   border-radius: 0;
   max-width: 500px;
  }
  .abtlistgrp .card .list-group i{
   margin-right: 20px;
  }
  .blog .abtlistgrp .card .list-group .btn {
   padding: .5rem 1rem;
   font-size: 1.25rem;
   border-radius: .3rem;
   width: 100%;
 }