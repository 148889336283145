
.rdw-editor-main {
     height: 100%;
    overflow: auto;
    box-sizing: border-box;
}
.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
  }
.editor-class {
  background-color:lightgray;
  padding: 1rem;
  border: 1px solid #ccc; 
}
.toolbar-class {
  border: 1px solid #ccc;
}
.quill {
  width: auto;
}
.ql-container.ql-snow {
    border: 2px solid #ccc;
    height: 400px;
    overflow: scroll;
  }