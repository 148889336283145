#laccordion-toggle {
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    padding: 1em;
    justify-content: left;
}
.accordion-item {
    list-style: none;
    text-align: left;
}
