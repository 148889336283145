/* Googlefont Poppins CDN Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
/*-----Sidebar---*/
#dsidebar{
  position: fixed;
  height: 100%;
  width: 280px;
  background: #0A2558;
  transition: all 0.5s ease;
  z-index: 999;
}
#dsidebar.close{
  width: 78px;
}
#dsidebar .logo-details{
  height: 80px;
  display: flex;
  align-items: center;
}
#dsidebar .logo-details i{
  font-size: 28px;
  font-weight: 500;
  color: #fff;
  min-width: 60px;
  text-align: center
}
#dsidebar .logo-details .logo_img{
    width: 30px;
    height: 30px;
    min-width: 30px;
    margin-left: 20px;
    margin-right: 10px;
}
#dsidebar .logo-details .logo_name{
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}
#dsidebar.close .logo-details .logo_name{
display: none;
}

#dsidebar .nav-links{
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto;
}
#dsidebar.close .nav-links{
  overflow: visible;
}
#dsidebar .nav-links::-webkit-scrollbar{
  display: none;
}
#dsidebar .nav-links li{
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}
#dsidebar .nav-links li:hover{
  background: #1d1b31;
}

#dsidebar.close .nav-links li .iocn-link{
  display: block
}
#dsidebar .nav-links li i{
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}
#dsidebar .nav-links li i.arrow{
 float: right;
 margin-right: 5px;
}
#dsidebar .nav-links li.showMenu i.arrow{
  transform: rotate(-180deg);
}
#dsidebar.close .nav-links i.arrow{
  display: none;
}
#dsidebar .nav-links li a{
  display: flex;
  align-items: center;
  text-decoration: none;
}
#dsidebar .nav-links li a .link_name{
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  transition: all 0.4s ease;
}
#dsidebar.close .nav-links li a .link_name{
  opacity: 0;
  pointer-events: none;
}
#dsidebar .nav-links li .sub-menu{
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #1d1b31;
  display: none;
}
#dsidebar .nav-links li.showMenu .sub-menu{
  display: block;
}
#dsidebar .nav-links li .sub-menu a{
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
#dsidebar .nav-links li .sub-menu a:hover{
  opacity: 1;
}
#dsidebar.close .nav-links li .sub-menu{
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
#dsidebar.close .nav-links li:hover .sub-menu{
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
#dsidebar .nav-links li .sub-menu .link_name{
  display: none;
}
#dsidebar.close .nav-links li .sub-menu .link_name{
  font-size: 18px;
  opacity: 1;
  display: block;
}
#dsidebar .nav-links li .sub-menu.blank{
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
#dsidebar .nav-links li:hover .sub-menu.blank{
  top: 50%;
  transform: translateY(-50%);
}
#dsidebar .nav-links .log_out{
  position: absolute;
  bottom: 0;
  width: 100%;
}

#dsidebar .nav-links li .iocn-link{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#dsidebar.close .nav-links li .iocn-link{
  display: block
}


/*-------Profile Detail-------*/
#dsidebar .profile-details{
  position: fixed;
  bottom: 0;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1b31;
  padding: 12px 0;
  transition: all 0.5s ease;
}
#dsidebar.close .profile-details{
  background: none;
}
#dsidebar.close .profile-details{
  width: 78px;
}
#dsidebar .profile-details .profile-content{
  display: flex;
  align-items: center;
}
#dsidebar .profile-details img{
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #1d1b31;
  transition: all 0.5s ease;
}
#dsidebar.close .profile-details img{
  padding: 10px;
}

#dsidebar .profile-details .profile_name,
#dsidebar .profile-details .job{
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}
#dsidebar.close .profile-details i,
#dsidebar.close .profile-details .profile_name,
#dsidebar.close .profile-details .job{
  display: none;
}
#dsidebar .profile-details .job{
  font-size: 12px;
}


/*-------Header-------*/
.home-section{
  position: relative;
  padding: 0;
  background: #f5f5f5;
  /*min-height: 100vh;*/
  width: calc(100% - 280px);
  left: 280px;
  transition: all 0.5s ease;
}
#dsidebar.close ~ .home-section{
  width: calc(100% - 78px);
  left: 78px;
}
.home-section nav{
  display: flex;
  justify-content: space-between;
  height: 80px;
  background: #fff;
  display: flex;
  align-items: center;
  position: fixed;
  width: calc(100% - 280px);
  left: 280px;
  z-index: 100;
  padding: 0 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
}
#dsidebar.close ~ .home-section nav{
  left: 78px;
  width: calc(100% - 78px);
}
.home-section nav .sidebar-button{
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
}
nav .sidebar-button i{
  font-size: 35px;
  margin-right: 10px;
  cursor: pointer;
}
.home-section nav .search-box{
  position: relative;
  height: 50px;
  max-width: 550px;
  width: 100%;
  margin: 0 20px;
}
/* nav .search-box input{
  height: 100%;
  width: 100%;
  outline: none;
  background: #F5F6FA;
  border: 2px solid #EFEEF1;
  border-radius: 6px;
  font-size: 18px;
  padding: 0 15px;
}
nav .search-box .bx-search{
  position: absolute;
  height: 40px;
  width: 40px;
  background: #2697FF;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 22px;
  transition: all 0.4 ease;
} */
.home-section nav .profile-details{
  display: flex;
  align-items: center;
  background: #F5F6FA;
  border: 2px solid #EFEEF1;
  border-radius: 6px;
  height: 50px;
  min-width: 60px;
  padding: 10px;
  cursor: pointer;
}
nav .profile-details img{
  height: 40px;
  width: 40px;
  border-radius: 6px;
  object-fit: cover;
}
nav .profile-details .admin_name{
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin: 0 10px;
  white-space: nowrap;
}
nav .profile-details i{
  font-size: 25px;
  color: #333;
}
.home-section .home-content{
  position: relative;
  padding-top: 104px;
}
.home-content .overview-boxes{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
 /* padding: 0 20px;*/
 padding: 20px;
  margin-bottom: 26px;
}

.overview-boxes .box{
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 4 - 15px);
  background: #fff;
  padding: 15px 14px;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);
}
.overview-boxes .box-topic{
  font-size: 20px;
  font-weight: 500;
}
.home-content .box .number{
  display: inline-block;
  font-size: 35px;
  margin-top: -6px;
  font-weight: 500;
}
.home-content .box .indicator{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.home-content .box .indicator i{
  height: 20px;
  width: 20px;
  background: #b08fda;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  margin-right: 5px;
}
.box .indicator i.down{
  background: #e87d88;
}
.home-content .box .indicator .text{
  font-size: 12px;
}
.home-content .box .award{
  display: inline-block;
  font-size: 32px;
  height: 50px;
  width: 50px;
  background: #cce5ff;
  line-height: 50px;
  text-align: center;
  color: #66b0ff;
  border-radius: 12px;
  margin: -5px 0 0 6px;
}
.home-content .box .cart.two{
   color: #2BD47D;
   background: #C0F2D8;
 }
.home-content .box .cart.three{
   color: #ffc233;
   background: #ffe8b3;
 }
.home-content .box .cart.four{
   color: #e05260;
   background: #f7d4d7;
 }
.home-content .total-order{
  font-size: 20px;
  font-weight: 500;
}
.home-content .sales-boxes{
  display: flex;
  justify-content: space-between;
  /* padding: 0 20px; */
}

/* left box */
.home-content .sales-boxes .recent-sales{
  width: 100%;
  background: #fff;
  padding: 20px 30px;
  margin: 0 20px;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.home-content .sales-boxes .sales-details{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sales-boxes .box .title{
  font-size: 24px;
  font-weight: 500;
  /* margin-bottom: 10px; */
}
.sales-boxes .sales-details li.topic{
  font-size: 20px;
  font-weight: 500;
}
.sales-boxes .sales-details li{
  list-style: none;
  margin: 8px 0;
}
.sales-boxes .sales-details li a{
  font-size: 18px;
  color: #333;
  font-size: 400;
  text-decoration: none;
}
.sales-boxes .box .button{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.sales-boxes .box .button a{
  color: #fff;
  background: #0A2558;
  padding: 4px 12px;
  font-size: 15px;
  font-weight: 400;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.3s ease;
}
.sales-boxes .box .button a:hover{
  background:  #0d3073;
}

/* Right box */
.home-content .sales-boxes .top-sales{
  width: 35%;
  background: #fff;
  padding: 20px 30px;
  margin: 0 20px 0 0;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.sales-boxes .top-sales li{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.sales-boxes .top-sales li a img{
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 12px;
  margin-right: 10px;
  background: #333;
}
.sales-boxes .top-sales li a{
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sales-boxes .top-sales li .product,
.price{
  font-size: 17px;
  font-weight: 400;
  color: #333;
}
/*Tab Area*/
/*----Tab Content----*/
.tab-wrapper nav{
  justify-content: start;
  padding-left:20px;
  height: 40px;
  /*margin-left: 30px;
  max-width: 79%;*/
  }
  
  .tab-item-wrapper{
      padding-top: 80px;
  }


/* Responsive Media Query */
/*
@media (max-width: 1240px) {
  #dsidebar{
    width: 60px;
  }
  #dsidebar.close{
    width: 220px;
  }
  .home-section{
    width: calc(100% - 60px);
    left: 60px;
  }
  #dsidebar.close ~ .home-section{
    /* width: calc(100% - 220px); 
    overflow: hidden;
    left: 220px;
  }
  .home-section nav{
    width: calc(100% - 60px);
    left: 60px;
  }
  #dsidebar.close ~ .home-section nav{
    width: calc(100% - 220px);
    left: 220px;
  }
}

@media (max-width: 1150px) {
  .home-content .sales-boxes{
    flex-direction: column;
  }
  .home-content .sales-boxes .box{
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 30px;
  }
  .home-content .sales-boxes .top-sales{
    margin: 0;
  }
}*/
@media (max-width: 1000px) {
  .overview-boxes .box{
    width: calc(100% / 2 - 15px);
    margin-bottom: 15px;
  }
}
@media (max-width: 700px) {
  nav .sidebar-button .dashboard,
  nav .profile-details .admin_name,
  nav .profile-details i{
    display: none;
  }
  .home-section nav .profile-details{
    height: 50px;
    min-width: 40px;
  }
  .home-content .sales-boxes .sales-details{
    width: 560px;
  }
}
@media (max-width: 550px) {
  .overview-boxes .box{
    width: 100%;
    margin-bottom: 15px;
  }
  #dsidebar.close ~ .home-section nav .profile-details{
    display: none;
  }
  #dsidebar.close .nav-links li .sub-menu{
    display: none;
  }
}

@media (max-width:390px) {
  #dsidebar { 
    width: 60px;
}
#dsidebar .logo-details {
  height: 80px;
  display: flex;
  align-items: center;
}
#dsidebar .logo-details .logo_img {
  width: 30px;
  height: 30px;
  min-width: 30px;
  margin-left: 20px;
  margin-right: 10px;
}
#dsidebar.close .logo-details .logo_name {
 display: none;
}
#dsidebar .logo-details .logo_name {
  display: none;
}
#dsidebar .profile-details {
  position: fixed;
  bottom: 0;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1b31;
  padding: 12px 0;
  transition: all 0.5s ease;
}
#dsidebar .profile-details img {
  height: 30px;
  width: 30px;
  min-width: 30px;
  border-radius: 8px;
  margin: 0 10px 0 20px;
}
#dsidebar .profile-details .job {
 display: none ;
}
#dsidebar .profile-details i{
  display: none ;
 }

}
